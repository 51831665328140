import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { FooterLanding, getFooterLandings } from '@/data/footer'
import ListNavItem from '../atoms/ListNavItem'
import FooterNav from '../molecules/FooterNav'
import FacebookIcon from '../atoms/SocialIcons/FacebookIcon'
import LinkedinIcon from '../atoms/SocialIcons/LinkedinIcon'
import InstagramIcon from '../atoms/SocialIcons/InstagramIcon'
import YoutubeIcon from '../atoms/SocialIcons/YoutubeIcon'
import TiktokIcon from '../atoms/SocialIcons/TiktokIcon'

const Footer: React.FC = () => {
  const { t, lang } = useTranslation('footer')

  const navFirst = {
    title: 'Bulldogjob',
    elements: [
      {
        title: t('privacy_policy'),
        url: '/privacy_policy',
        showPl: true,
        showEn: true,
      },
      {
        title: t('regulations'),
        url: '/terms',
        showPl: true,
        showEn: true,
      },
      {
        title: t('career'),
        url: '/career',
        showPl: true,
        showEn: false,
      },
      {
        title: t('contact'),
        url: '/contact',
        showPl: true,
        showEn: true,
      },
    ],
  }

  const navSecond = {
    title: 'Hello YOU!',
    elements: [
      {
        title: t('job_offers'),
        url: '/companies/jobs',
        showPl: true,
        showEn: true,
      },
      {
        title: t('profiles'),
        url: '/companies/profiles',
        showPl: true,
        showEn: true,
      },
      {
        title: t('readme'),
        url: '/readme',
        showPl: true,
        showEn: true,
      },
      {
        title: t('report'),
        url: '/it-report/2025',
        showPl: true,
        showEn: true,
      },
      {
        title: t('b2c_account'),
        url: '/auth/register',
        showPl: true,
        showEn: true,
      },
    ],
  }

  return (
    <footer className='bg-black  px-6 md:px-0 text-white'>
      <div className='py-14 md:py-28'>
        <div className=' container-medium mx-auto py-0 flex flex-wrap justify-between'>
          <FooterNav
            title={navFirst.title}
            elements={navFirst.elements}
            withLang
          />

          <FooterNav title={navSecond.title} elements={navSecond.elements} />
          <div className='w-full md:w-auto mb-6 md:mb-0'>
            <header className='mb-6'>
              <h4 className='text-3xl md:text-2xl text-action'>
                {t('for_employees')}
              </h4>
            </header>
            <nav>
              <ul>
                {getFooterLandings(lang).map(
                  (element: FooterLanding, index) => (
                    <ListNavItem key={index}>
                      <Link
                        href={element.url}
                        prefetch={false}
                        title={element.menuTitle}
                        rel='nofollow'
                        className='hover:text-primary  transition-colors'
                      >
                        {element.menuTitle}
                      </Link>
                    </ListNavItem>
                  )
                )}
              </ul>
            </nav>
          </div>
          <nav className='w-full md:w-auto flex md:block justify-center flex-wrap gap-5 text-center'>
            <p className='md:mb-10'>
              <a
                href='https://www.facebook.com/Bulldogjob/'
                rel='nofollow noreferrer'
                target='_blank'
                title='Facebook'
                className='hover:text-primary p-5 duration-200'
              >
                <FacebookIcon width={30} />
              </a>
            </p>
            <p className='md:mb-10'>
              <a
                href='https://www.linkedin.com/company/bulldogjob/'
                rel='nofollow noreferrer'
                target='_blank'
                title='LinkedIn'
                className='hover:text-primary p-5 duration-200'
              >
                <LinkedinIcon width={30} />
              </a>
            </p>
            <p className='md:mb-10'>
              <a
                href='https://www.instagram.com/bulldogjob/'
                rel='nofollow noreferrer'
                target='_blank'
                title='Instagram'
                className='hover:text-primary p-5 duration-200'
              >
                <InstagramIcon width={30} />
              </a>
            </p>
            <p className='md:mb-10'>
              <a
                href='https://www.tiktok.com/@bulldogjob.pl'
                rel='nofollow noreferrer'
                target='_blank'
                title='TikTok'
                className='hover:text-primary p-5 duration-200'
              >
                <TiktokIcon width={30} />
              </a>
            </p>
            <p className='md:mb-10'>
              <a
                href='https://www.youtube.com/@bulldogjob'
                rel='nofollow noreferrer'
                target='_blank'
                title='YouTube'
                className='hover:text-primary p-5 duration-200'
              >
                <YoutubeIcon width={30} />
              </a>
            </p>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
