import React, { useContext, useEffect, useState } from 'react'

const AnalyticsPushContext = React.createContext<any>(null)

export function useAnalyticsPush() {
  return useContext(AnalyticsPushContext)
}

export function AnalyticsProvider({ children }: any) {
  const [consentsReady, setConsentsReady] = useState<boolean>(false)
  const [store, setStore] = useState<any[]>([])

  function rawPush(event: any) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
    window.dataLayer.push(event)
  }

  useEffect(() => {
    if (consentsReady) {
      store.forEach((event) => rawPush(event))
    }
  }, [consentsReady])

  function pushEvent(event: any) {
    if (consentsReady) {
      rawPush(event)
    } else {
      setStore((s) => [...s, event])
    }
  }

  const value = {
    pushEvent,
    setConsentsReady,
    consentsReady,
  }

  return (
    <AnalyticsPushContext.Provider value={value}>
      {children}
    </AnalyticsPushContext.Provider>
  )
}
