import Cookies from 'js-cookie'

export const saveConsentsToCookie = (
  analyticsConsent: boolean,
  marketingConsent: boolean
) => {
  const value = [
    analyticsConsent ? '1' : '0',
    marketingConsent ? '1' : '0',
  ].join(',')
  Cookies.set('consents', value, { expires: 365 })
}

export const getConsentsFromCookie = () => {
  const value = Cookies.get('consents')
  if (value) return value.split(',').map((el) => el === '1')
  return undefined
}
