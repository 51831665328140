import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISO8601Date: any
  ISO8601DateTime: any
  JSON: any
  Upload: any
}

export type Auth_Provider_Credentials = {
  email: Scalars['String']
  password: Scalars['String']
}

export type Admin = {
  __typename?: 'Admin'
  avatar?: Maybe<Image>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ApplyCv */
export type ApplyCvInput = {
  candidateAttributes: ProposalCandidateAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  consents?: InputMaybe<Array<ConsentInfo>>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ApplyCv */
export type ApplyCvPayload = {
  __typename?: 'ApplyCvPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Scalars['JSON']>
  poposal?: Maybe<Proposal>
}

/** Autogenerated input type of ApplyExternal */
export type ApplyExternalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ApplyExternal */
export type ApplyExternalPayload = {
  __typename?: 'ApplyExternalPayload'
  applyUrl?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ApplyWithProfile */
export type ApplyWithProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  consents?: InputMaybe<Array<ConsentInfo>>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  proposalAttributes: ProposalAttributes
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ApplyWithProfile */
export type ApplyWithProfilePayload = {
  __typename?: 'ApplyWithProfilePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Scalars['JSON']>
  proposal?: Maybe<Proposal>
}

export type Article = {
  __typename?: 'Article'
  articleAttachment?: Maybe<Image>
  blocked: Scalars['Boolean']
  company?: Maybe<Company>
  content: Scalars['String']
  id: Scalars['ID']
  isAlive: Scalars['Boolean']
  locale?: Maybe<Scalars['String']>
  paid: Scalars['Boolean']
  publishedAt: Scalars['ISO8601DateTime']
  structuredData?: Maybe<StructuredData>
  title: Scalars['String']
  views: Scalars['Int']
  wordCount: Scalars['Int']
}

export type Author = {
  __typename?: 'Author'
  bio?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  companyLogo?: Maybe<Image>
  email?: Maybe<Scalars['String']>
  github?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kind?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Image>
  position?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  www?: Maybe<Scalars['String']>
}

export type Benefit = {
  __typename?: 'Benefit'
  category?: Maybe<Scalars['String']>
  localizedName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  owner?: Maybe<Scalars['Boolean']>
}

export type BoostWhere = {
  factor?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type Candidate = {
  __typename?: 'Candidate'
  avatar?: Maybe<Image>
  candidateSearch?: Maybe<CandidateSearch>
  cvFileName?: Maybe<Scalars['String']>
  displayedName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  employmentType?: Maybe<Array<Scalars['String']>>
  expectedSalary?: Maybe<Scalars['Int']>
  likedJobs?: Maybe<Array<Scalars['ID']>>
  linkedin?: Maybe<Scalars['String']>
  location?: Maybe<Array<Scalars['String']>>
  minSalary?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  otherTech?: Maybe<Array<Scalars['String']>>
  phone?: Maybe<Scalars['String']>
  rejectedJobs?: Maybe<Array<Scalars['ID']>>
  relocation?: Maybe<Scalars['Boolean']>
  remote?: Maybe<Scalars['Boolean']>
  role?: Maybe<Array<Scalars['String']>>
  seniority?: Maybe<Scalars['String']>
  tech?: Maybe<Array<Scalars['String']>>
  tech1?: Maybe<Scalars['String']>
  tech1Experience?: Maybe<Scalars['String']>
  tech2?: Maybe<Scalars['String']>
  tech2Experience?: Maybe<Scalars['String']>
}

/** The connection type for Job. */
export type CandidateJobs = {
  __typename?: 'CandidateJobs'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobsEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Job>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidateSearch = {
  __typename?: 'CandidateSearch'
  city?: Maybe<Array<Scalars['String']>>
  experienceLevel?: Maybe<Array<Scalars['String']>>
  role?: Maybe<Array<Scalars['String']>>
  skills?: Maybe<Array<Scalars['String']>>
}

export type ClaimCompanyCredentialsInput = {
  companyId: Scalars['ID']
  email: Scalars['String']
  name: Scalars['String']
  newsletter: Scalars['Boolean']
  phone: Scalars['String']
  position: Scalars['String']
  terms: Scalars['Boolean']
}

/** Autogenerated input type of ClaimCompany */
export type ClaimCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<ClaimCompanyCredentialsInput>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

export type Comment = {
  __typename?: 'Comment'
  createdAt: Scalars['ISO8601DateTime']
  creator?: Maybe<Creator>
  hidden?: Maybe<Scalars['Boolean']>
  htmlContent?: Maybe<Scalars['String']>
  id: Scalars['ID']
  markdownContent?: Maybe<Scalars['String']>
  parentCommentId?: Maybe<Scalars['ID']>
  readable: Readable
  score?: Maybe<Scalars['Int']>
}

/** The connection type for Comment. */
export type Comments = {
  __typename?: 'Comments'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentsEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type CommentsEdge = {
  __typename?: 'CommentsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Comment>
}

/** The connection type for Company. */
export type Companies = {
  __typename?: 'Companies'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompaniesEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type CompaniesEdge = {
  __typename?: 'CompaniesEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Company>
}

export enum CompaniesSearchOrderField {
  DEFAULT = 'DEFAULT',
  EMPLOYEES = 'EMPLOYEES',
  JOBS = 'JOBS',
  NAME = 'NAME',
  REVIEWS = 'REVIEWS',
  REVIEW_VALUE = 'REVIEW_VALUE',
}

export type Company = {
  __typename?: 'Company'
  activeOffersCount?: Maybe<Scalars['Int']>
  address?: Maybe<Scalars['String']>
  benefits: Array<Benefit>
  coverPhoto?: Maybe<Image>
  currentLayout?: Maybe<Scalars['String']>
  developmentProcessRating?: Maybe<Scalars['Float']>
  employeeQuotes: Array<Employee>
  employees?: Maybe<Scalars['String']>
  engineeringRating?: Maybe<Scalars['Float']>
  environment: Environment
  facebook?: Maybe<Scalars['String']>
  galleryAssets?: Maybe<Array<GalleryItem>>
  github?: Maybe<Scalars['String']>
  history?: Maybe<Scalars['String']>
  howWeCode: HowWeCode
  howWeManage: HowWeManage
  howWeTest: HowWeTest
  id: Scalars['ID']
  instagram?: Maybe<Scalars['String']>
  jobCover?: Maybe<Image>
  linkedin?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  locations: Array<Location>
  logo?: Maybe<Image>
  managed?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  offices: Array<Office>
  people: Array<Person>
  postalCode?: Maybe<Scalars['String']>
  professionalDevelopmentRating?: Maybe<Scalars['Float']>
  published?: Maybe<Scalars['Boolean']>
  ready?: Maybe<Scalars['Boolean']>
  reviewsCount?: Maybe<Scalars['Int']>
  salaryBracketsHidden: Scalars['Boolean']
  salaryRating?: Maybe<Scalars['Float']>
  satisfactionRating?: Maybe<Scalars['Float']>
  skills?: Maybe<Array<Scalars['String']>>
  sorting?: Maybe<Scalars['String']>
  summaryRating?: Maybe<Scalars['Float']>
  teamworkRating?: Maybe<Scalars['Float']>
  techUsage?: Maybe<TechUsage>
  themeColor?: Maybe<Scalars['String']>
  tools: Tools
  topTech: Scalars['Boolean']
  topTechEmp?: Maybe<TopTech>
  twitter?: Maybe<Scalars['String']>
  verified: Scalars['Boolean']
  visible?: Maybe<Scalars['Boolean']>
  website?: Maybe<Scalars['String']>
}

export type CompanyFilters = {
  capital?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Array<Scalars['String']>>
  companyName?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Array<Scalars['ID']>>
  industryId?: InputMaybe<Scalars['String']>
  letter?: InputMaybe<Scalars['String']>
  q?: InputMaybe<Scalars['String']>
  skills?: InputMaybe<Array<Scalars['String']>>
  topTech?: InputMaybe<Scalars['Boolean']>
  verified?: InputMaybe<Scalars['Boolean']>
}

export type CompanySearchOrderBy = {
  direction?: InputMaybe<OrderDirection>
  field: CompaniesSearchOrderField
}

export type Consent = {
  __typename?: 'Consent'
  companyId: Scalars['ID']
  id: Scalars['ID']
  required: Scalars['Boolean']
  text: Scalars['String']
}

export type ConsentInfo = {
  consentId: Scalars['ID']
  value: Scalars['Boolean']
}

export type CreateCandidateCredentialsInput = {
  confirmPassword: Scalars['String']
  email: Scalars['String']
  fullName: Scalars['String']
  newsletter?: InputMaybe<Scalars['Boolean']>
  password: Scalars['String']
}

/** Autogenerated input type of CreateCandidate */
export type CreateCandidateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<CreateCandidateCredentialsInput>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of CreateComment */
export type CreateCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  markdownContent: Scalars['String']
  parentCommentId?: InputMaybe<Scalars['ID']>
  readableId: Scalars['ID']
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateComment */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  comment?: Maybe<Comment>
  errors?: Maybe<Scalars['JSON']>
}

/** Autogenerated input type of CreateReview */
export type CreateReviewInput = {
  candidateId?: InputMaybe<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  comment: Scalars['String']
  companyId: Scalars['ID']
  developmentProcessRating: Scalars['Float']
  employmentStatus?: InputMaybe<Scalars['Boolean']>
  engineeringRating: Scalars['Float']
  language?: InputMaybe<LocaleEnum>
  location: Scalars['String']
  policy?: InputMaybe<Scalars['Boolean']>
  position: Scalars['String']
  professionalDevelopmentRating: Scalars['Float']
  referer?: InputMaybe<Scalars['String']>
  referralCode?: InputMaybe<Scalars['String']>
  salaryRating: Scalars['Float']
  satisfactionRating: Scalars['Float']
  teamworkRating: Scalars['Float']
  verificationLink: Scalars['String']
}

export type CreateUserCredentialsInput = {
  companyName: Scalars['String']
  email: Scalars['String']
  name: Scalars['String']
  newsletter: Scalars['Boolean']
  password: Scalars['String']
  phone: Scalars['String']
  terms: Scalars['Boolean']
}

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<CreateUserCredentialsInput>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

export type Creator = {
  __typename?: 'Creator'
  avatar?: Maybe<Image>
  displayedName?: Maybe<Scalars['String']>
}

/** Autogenerated input type of Dislike */
export type DislikeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of Dislike */
export type DislikePayload = {
  __typename?: 'DislikePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** Autogenerated input type of DownvoteComment */
export type DownvoteCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  commentId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of DownvoteComment */
export type DownvoteCommentPayload = {
  __typename?: 'DownvoteCommentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Employee = {
  __typename?: 'Employee'
  github?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Image>
  position?: Maybe<Scalars['String']>
  quote?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
}

export enum EmploymentEnum {
  b2b = 'b2b',
  contract = 'contract',
  employment = 'employment',
}

export type Environment = {
  __typename?: 'Environment'
  paidVacations?: Maybe<Scalars['String']>
  remotePossible?: Maybe<Scalars['Int']>
}

export enum EventActionEnum {
  click = 'click',
  download = 'download',
  login = 'login',
  register = 'register',
  show = 'show',
}

export type GalleryItem = {
  __typename?: 'GalleryItem'
  assetUrl: Scalars['String']
  description?: Maybe<Scalars['String']>
  thumbnailUrl: Scalars['String']
  type: GalleryItemTypeEnum
}

export enum GalleryItemTypeEnum {
  Photo = 'Photo',
  Video = 'Video',
}

/** Autogenerated input type of Hide */
export type HideInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of Hide */
export type HidePayload = {
  __typename?: 'HidePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type HowWeCode = {
  __typename?: 'HowWeCode'
  bdd?: Maybe<Scalars['Boolean']>
  codeMetrics?: Maybe<Scalars['Boolean']>
  codeReview?: Maybe<Scalars['Boolean']>
  knowledgeDatabase?: Maybe<Scalars['Boolean']>
  pairProgramming?: Maybe<Scalars['Boolean']>
  staticCodeAnalysis?: Maybe<Scalars['Boolean']>
  styleGuide?: Maybe<Scalars['Boolean']>
  tdd?: Maybe<Scalars['Boolean']>
  versionControlDescription?: Maybe<Scalars['String']>
}

export type HowWeManage = {
  __typename?: 'HowWeManage'
  architecturalDecisions?: Maybe<Scalars['String']>
  canSwitchProjects?: Maybe<Scalars['Boolean']>
  concurentProjects?: Maybe<Scalars['Int']>
  methodology?: Maybe<Scalars['String']>
  projectSoftware?: Maybe<Scalars['String']>
  teamDesign?: Maybe<Scalars['Int']>
  teamDev?: Maybe<Scalars['Int']>
  teamPm?: Maybe<Scalars['Int']>
  teamQa?: Maybe<Scalars['Int']>
  techDecisions?: Maybe<Scalars['String']>
}

export type HowWeTest = {
  __typename?: 'HowWeTest'
  automatedTesting?: Maybe<Scalars['Boolean']>
  ci?: Maybe<Scalars['Boolean']>
  integration?: Maybe<Scalars['Boolean']>
  manualTesting?: Maybe<Scalars['Boolean']>
  performance?: Maybe<Scalars['Boolean']>
  security?: Maybe<Scalars['Boolean']>
  system?: Maybe<Scalars['Boolean']>
  unit?: Maybe<Scalars['Boolean']>
}

export type Image = {
  __typename?: 'Image'
  url?: Maybe<Scalars['String']>
}

export type ImageUrlArgs = {
  style?: InputMaybe<Scalars['String']>
}

export type Job = {
  __typename?: 'Job'
  applied?: Maybe<Scalars['Boolean']>
  applyUrl?: Maybe<Scalars['String']>
  applyViaCv?: Maybe<Scalars['Boolean']>
  applyViaLink?: Maybe<Scalars['Boolean']>
  applyViaProfile?: Maybe<Scalars['Boolean']>
  b2bSalary?: Maybe<Salary>
  benefits?: Maybe<Array<Benefit>>
  city?: Maybe<Scalars['String']>
  company: Company
  consents?: Maybe<Array<Consent>>
  contractB2b?: Maybe<Scalars['Boolean']>
  contractEmployment?: Maybe<Scalars['Boolean']>
  contractOther?: Maybe<Scalars['Boolean']>
  denominatedSalaryLong: Salary
  denominatedSalaryShort: Salary
  details?: Maybe<Scalars['String']>
  employmentSalary?: Maybe<Salary>
  employmentType: Scalars['String']
  endsAt?: Maybe<Scalars['ISO8601DateTime']>
  environment: Environment
  experienceLevel: Scalars['String']
  hiddenBrackets?: Maybe<Scalars['Boolean']>
  highlight?: Maybe<Scalars['Boolean']>
  howWeCode: HowWeCode
  howWeManage: HowWeManage
  howWeTest: HowWeTest
  id: Scalars['ID']
  internalApplication: Scalars['Boolean']
  locale: Scalars['String']
  locations?: Maybe<Array<JobLocation>>
  mainTechnology?: Maybe<Scalars['String']>
  matchingUserBrackets: Scalars['Boolean']
  minExperienceInYears?: Maybe<Scalars['Int']>
  offer?: Maybe<Scalars['String']>
  otherRequirements?: Maybe<Scalars['String']>
  otherSalary?: Maybe<Salary>
  position?: Maybe<Scalars['String']>
  projectDescription?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  recruitmentProcess?: Maybe<Array<Scalars['String']>>
  recruitmentProcessLength?: Maybe<Scalars['Int']>
  redirectTo?: Maybe<Scalars['String']>
  relocationFlag?: Maybe<Scalars['String']>
  remote?: Maybe<Scalars['Boolean']>
  requirements?: Maybe<Scalars['String']>
  responsibilities?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  showEn?: Maybe<Scalars['Boolean']>
  showPl?: Maybe<Scalars['Boolean']>
  showSalary: Scalars['Boolean']
  state?: Maybe<Scalars['String']>
  structuredData?: Maybe<StructuredData>
  technologyTags?: Maybe<Array<Scalars['String']>>
  tools: Tools
  topBanner?: Maybe<Image>
  workFrom?: Maybe<Array<Scalars['String']>>
  workModes?: Maybe<Array<Scalars['String']>>
}

export type JobFilters = {
  city?: InputMaybe<Array<Scalars['String']>>
  company?: InputMaybe<Array<Scalars['ID']>>
  companyIds?: InputMaybe<Array<Scalars['Int']>>
  contractType?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['String']>
  daysAgo?: InputMaybe<Scalars['String']>
  employmentType?: InputMaybe<Array<Scalars['String']>>
  endsIn?: InputMaybe<Scalars['String']>
  experienceLevel?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Array<Scalars['ID']>>
  locationDistance?: InputMaybe<Scalars['String']>
  q?: InputMaybe<Scalars['String']>
  remote?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<Array<Scalars['String']>>
  salaryBrackets?: InputMaybe<Salary_Brackets>
  skills?: InputMaybe<Array<Scalars['String']>>
  topTech?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<Scalars['String']>
  ukraineRelocation?: InputMaybe<Scalars['Boolean']>
  withSalary?: InputMaybe<Scalars['Boolean']>
}

export type JobLocation = {
  __typename?: 'JobLocation'
  address?: Maybe<Scalars['String']>
  location?: Maybe<Location>
}

export type JobSalaryMatch = {
  __typename?: 'JobSalaryMatch'
  matchingUserBrackets: Scalars['Boolean']
}

/** The connection type for Job. */
export type Jobs = {
  __typename?: 'Jobs'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobsEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Job>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type JobsEdge = {
  __typename?: 'JobsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Job>
}

export type JobsSearchOrderBy = {
  direction?: InputMaybe<OrderDirection>
  field: JobsSearchOrderField
}

export enum JobsSearchOrderField {
  COMPANY = 'COMPANY',
  DEFAULT = 'DEFAULT',
  PUBLISHED = 'PUBLISHED',
  SALARY = 'SALARY',
  SHOW_SALARY = 'SHOW_SALARY',
  TITLE = 'TITLE',
}

export type LandingPage = {
  __typename?: 'LandingPage'
  createdAt: Scalars['ISO8601DateTime']
  description?: Maybe<Scalars['String']>
  externalUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  language: Scalars['String']
  layout?: Maybe<Scalars['String']>
  menuTitle?: Maybe<Scalars['String']>
  showInMenu?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** Autogenerated input type of LikeOffer */
export type LikeOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of LikeOffer */
export type LikeOfferPayload = {
  __typename?: 'LikeOfferPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export enum LocaleEnum {
  en = 'en',
  pl = 'pl',
}

export type Location = {
  __typename?: 'Location'
  cityEn?: Maybe<Scalars['String']>
  cityPl?: Maybe<Scalars['String']>
  countryEn?: Maybe<Scalars['String']>
  countryPl?: Maybe<Scalars['String']>
  districtEn?: Maybe<Scalars['String']>
  districtPl?: Maybe<Scalars['String']>
}

export type LoginUser = {
  __typename?: 'LoginUser'
  errors?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type Metadata = {
  __typename?: 'Metadata'
  breadcrumbJsonLd?: Maybe<Scalars['JSON']>
  canonicalUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  hreflangEnUrl?: Maybe<Scalars['String']>
  hreflangPlUrl?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  jsonLd?: Maybe<Scalars['JSON']>
  noindex?: Maybe<Scalars['Boolean']>
  subheader?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  applyCv?: Maybe<ApplyCvPayload>
  applyExternal?: Maybe<ApplyExternalPayload>
  applyWithProfile?: Maybe<ApplyWithProfilePayload>
  claimCompany?: Maybe<SuccessErrors>
  createCandidate?: Maybe<SuccessErrors>
  createComment?: Maybe<CreateCommentPayload>
  createReview?: Maybe<SuccessErrors>
  createUser?: Maybe<SuccessErrors>
  dislikeOffer?: Maybe<DislikePayload>
  downvoteComment?: Maybe<DownvoteCommentPayload>
  hideOffer?: Maybe<HidePayload>
  likeOffer?: Maybe<LikeOfferPayload>
  registerB2cLead?: Maybe<RegisterB2cLeadPayload>
  registerEvent?: Maybe<RegisterPayload>
  registerLead?: Maybe<RegisterLeadPayload>
  removeCandidate?: Maybe<LoginUser>
  resetCandidatePasswordByToken?: Maybe<SuccessErrors>
  resetCandidatePasswordInstructions?: Maybe<SuccessErrors>
  resetUserPasswordByToken?: Maybe<SuccessErrors>
  resetUserPasswordInstructions?: Maybe<SuccessErrors>
  sendReviewOpinion?: Maybe<SuccessErrors>
  showOffer?: Maybe<ShowPayload>
  signinCandidate?: Maybe<LoginUser>
  signinOauth?: Maybe<LoginUser>
  signinUser?: Maybe<LoginUser>
  toggleReactionReadable?: Maybe<ToggleReactionReadablePayload>
  updateMinSalary?: Maybe<SuccessErrors>
  updateReview?: Maybe<SuccessErrors>
  upvoteComment?: Maybe<UpvoteCommentPayload>
}

export type MutationApplyCvArgs = {
  input: ApplyCvInput
}

export type MutationApplyExternalArgs = {
  input: ApplyExternalInput
}

export type MutationApplyWithProfileArgs = {
  input: ApplyWithProfileInput
}

export type MutationClaimCompanyArgs = {
  input: ClaimCompanyInput
}

export type MutationCreateCandidateArgs = {
  input: CreateCandidateInput
}

export type MutationCreateCommentArgs = {
  input: CreateCommentInput
}

export type MutationCreateReviewArgs = {
  input: CreateReviewInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDislikeOfferArgs = {
  input: DislikeInput
}

export type MutationDownvoteCommentArgs = {
  input: DownvoteCommentInput
}

export type MutationHideOfferArgs = {
  input: HideInput
}

export type MutationLikeOfferArgs = {
  input: LikeOfferInput
}

export type MutationRegisterB2cLeadArgs = {
  input: RegisterB2cLeadInput
}

export type MutationRegisterEventArgs = {
  input: RegisterInput
}

export type MutationRegisterLeadArgs = {
  input: RegisterLeadInput
}

export type MutationRemoveCandidateArgs = {
  input: RemoveCandidateInput
}

export type MutationResetCandidatePasswordByTokenArgs = {
  input: ResetCandidatePasswordByTokenInput
}

export type MutationResetCandidatePasswordInstructionsArgs = {
  input: ResetCandidatePasswordInstructionsInput
}

export type MutationResetUserPasswordByTokenArgs = {
  input: ResetUserPasswordByTokenInput
}

export type MutationResetUserPasswordInstructionsArgs = {
  input: ResetUserPasswordInstructionsInput
}

export type MutationSendReviewOpinionArgs = {
  input: SendReviewOpinionInput
}

export type MutationShowOfferArgs = {
  input: ShowInput
}

export type MutationSigninCandidateArgs = {
  input: SignInCandidateInput
}

export type MutationSigninOauthArgs = {
  input: SignInOauthInput
}

export type MutationSigninUserArgs = {
  input: SignInUserInput
}

export type MutationToggleReactionReadableArgs = {
  input: ToggleReactionReadableInput
}

export type MutationUpdateMinSalaryArgs = {
  input: UpdateMinSalaryInput
}

export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput
}

export type MutationUpvoteCommentArgs = {
  input: UpvoteCommentInput
}

export type News = {
  __typename?: 'News'
  authorAvatar?: Maybe<Image>
  authorCompany?: Maybe<Scalars['String']>
  authorName?: Maybe<Scalars['String']>
  authorPosition?: Maybe<Scalars['String']>
  categories?: Maybe<Scalars['String']>
  content: Scalars['String']
  id: Scalars['ID']
  locale?: Maybe<Scalars['String']>
  publishedAt: Scalars['ISO8601DateTime']
  structuredData?: Maybe<StructuredData>
  title: Scalars['String']
  views: Scalars['Int']
}

export type O_Auth_Credentials = {
  expires: Scalars['Boolean']
  expiresAt: Scalars['Int']
  refreshToken: Scalars['String']
  token: Scalars['String']
}

export type O_Auth_Input = {
  credentials: O_Auth_Credentials
  info: O_Auth_User_Info
  provider: Scalars['String']
  uid: Scalars['String']
}

export type O_Auth_User_Info = {
  email: Scalars['String']
  emailVerified?: InputMaybe<Scalars['Boolean']>
  image?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type Office = {
  __typename?: 'Office'
  address?: Maybe<Scalars['String']>
  location: Location
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PageEnum {
  CompanyDetails = 'CompanyDetails',
  CompanyList = 'CompanyList',
  Generic = 'Generic',
  Home = 'Home',
  HrReadableDetails = 'HrReadableDetails',
  JobDetails = 'JobDetails',
  JobList = 'JobList',
  ReadableDetails = 'ReadableDetails',
  ReadableList = 'ReadableList',
  TopTechEmployer = 'TopTechEmployer',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type Person = {
  __typename?: 'Person'
  insight?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Image>
  position?: Maybe<Scalars['String']>
}

export type Proposal = {
  __typename?: 'Proposal'
  id: Scalars['ID']
}

export type ProposalAttributes = {
  email: Scalars['String']
  employmentType?: InputMaybe<Array<EmploymentEnum>>
  expectedSalary?: InputMaybe<Scalars['Int']>
  linkedin?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Array<Scalars['String']>>
  minSalary?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  otherTech?: InputMaybe<Array<Scalars['String']>>
  phone: Scalars['String']
  relocation?: InputMaybe<Scalars['Boolean']>
  remote?: InputMaybe<Scalars['Boolean']>
  role: Array<RoleEnum>
  seniority: SeniorityEnum
  tech?: InputMaybe<Array<Scalars['String']>>
  tech1?: InputMaybe<Scalars['String']>
  tech1Experience?: InputMaybe<Scalars['String']>
  tech2?: InputMaybe<Scalars['String']>
  tech2Experience?: InputMaybe<Scalars['String']>
}

export type ProposalCandidateAttributes = {
  cv?: InputMaybe<Scalars['Upload']>
  email: Scalars['String']
  linkedin?: InputMaybe<Scalars['String']>
  message?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  article?: Maybe<Article>
  author?: Maybe<Author>
  bestAuthors?: Maybe<Array<Author>>
  candidateJobs: CandidateJobs
  comments: Comments
  company?: Maybe<Company>
  getReadablesTerms?: Maybe<Scalars['JSON']>
  job?: Maybe<Job>
  jobSalaryMatch: JobSalaryMatch
  jobsLocations?: Maybe<Array<Scalars['String']>>
  landingPages?: Maybe<Array<LandingPage>>
  me?: Maybe<Signable>
  metadata?: Maybe<Metadata>
  news?: Maybe<News>
  readable?: Maybe<Readable>
  recommendations?: Maybe<Array<Recommendation>>
  /** List of searchable reviews */
  reviews: Reviews
  salaryStats?: Maybe<SalaryStatsType>
  /** List of searchable companies */
  searchCompanies: Companies
  /** List of searchable jobs */
  searchJobs: Jobs
  /** List of searchable readables */
  searchReadables: Readables
}

export type QueryArticleArgs = {
  id: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
}

export type QueryAuthorArgs = {
  id: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
}

export type QueryCandidateJobsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  candidateJob: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  readableId: Scalars['ID']
}

export type QueryCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<LocaleEnum>
  viewToken?: InputMaybe<Scalars['String']>
}

export type QueryGetReadablesTermsArgs = {
  language: LocaleEnum
}

export type QueryJobArgs = {
  id?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<LocaleEnum>
  token?: InputMaybe<Scalars['String']>
  viewToken?: InputMaybe<Scalars['String']>
}

export type QueryJobSalaryMatchArgs = {
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  minSalary: Scalars['Int']
}

export type QueryJobsLocationsArgs = {
  amount?: InputMaybe<Scalars['Int']>
  language: LocaleEnum
}

export type QueryLandingPagesArgs = {
  language: LocaleEnum
}

export type QueryMetadataArgs = {
  companyFilters?: InputMaybe<CompanyFilters>
  id?: InputMaybe<Scalars['ID']>
  jobFilters?: InputMaybe<JobFilters>
  language: LocaleEnum
  pageType: PageEnum
  readableFilters?: InputMaybe<ReadableFilters>
  url?: InputMaybe<Scalars['String']>
}

export type QueryNewsArgs = {
  id: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
}

export type QueryReadableArgs = {
  language?: InputMaybe<LocaleEnum>
  readableId: Scalars['ID']
  readableType?: InputMaybe<Scalars['String']>
  viewToken?: InputMaybe<Scalars['String']>
}

export type QueryRecommendationsArgs = {
  boostWhere?: InputMaybe<BoostWhere>
  exclude?: InputMaybe<Array<Scalars['ID']>>
  language: LocaleEnum
  minResults?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  resourceId: Scalars['ID']
  resourceType: SearchableEnum
  returnType: SearchableEnum
}

export type QueryReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<ReviewsOrderBy>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerySalaryStatsArgs = {
  id?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<LocaleEnum>
}

export type QuerySearchCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  exclude?: InputMaybe<Array<Scalars['ID']>>
  filters?: InputMaybe<CompanyFilters>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<CompanySearchOrderBy>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerySearchJobsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  boostWhere?: InputMaybe<BoostWhere>
  exclude?: InputMaybe<Array<Scalars['ID']>>
  filters?: InputMaybe<JobFilters>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<JobsSearchOrderBy>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerySearchReadablesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  exclude?: InputMaybe<Array<Scalars['ID']>>
  filters?: InputMaybe<ReadableFilters>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<LocaleEnum>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<ReadableSearchOrderBy>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type Readable = {
  __typename?: 'Readable'
  author?: Maybe<Author>
  authorCompany?: Maybe<Scalars['String']>
  authorName?: Maybe<Scalars['String']>
  authorPosition?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Translations>>
  company?: Maybe<Company>
  content?: Maybe<Scalars['String']>
  cover?: Maybe<Image>
  delta?: Maybe<Scalars['Boolean']>
  experiences?: Maybe<Scalars['String']>
  forHr?: Maybe<Scalars['Boolean']>
  forIt?: Maybe<Scalars['Boolean']>
  hasAuthor?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  intro?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  mainTag?: Maybe<Scalars['String']>
  paid?: Maybe<Scalars['Boolean']>
  positions?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['ISO8601Date']>
  readableId: Scalars['ID']
  readableType: Scalars['String']
  relatedJobs?: Maybe<Array<Job>>
  relatedReadables?: Maybe<Array<Readable>>
  roles?: Maybe<Array<Translations>>
  slug?: Maybe<Scalars['String']>
  structuredData?: Maybe<StructuredData>
  tags?: Maybe<Array<Translations>>
  thumbsup?: Maybe<Scalars['Int']>
  thumbsupped?: Maybe<Scalars['Boolean']>
  tier?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated?: Maybe<Scalars['ISO8601Date']>
  wordCount?: Maybe<Scalars['String']>
}

export enum ReadableCategories {
  around_it = 'around_it',
  career = 'career',
  event = 'event',
  law_and_tax = 'law_and_tax',
  news = 'news',
  start_in_it = 'start_in_it',
  tech = 'tech',
  wages = 'wages',
  youtube = 'youtube',
}

export type ReadableFilters = {
  authorId?: InputMaybe<Scalars['ID']>
  categories?: InputMaybe<Array<ReadableCategories>>
  company?: InputMaybe<Scalars['ID']>
  forHr?: InputMaybe<Scalars['Boolean']>
  forIt?: InputMaybe<Scalars['Boolean']>
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Array<Scalars['ID']>>
  q?: InputMaybe<Scalars['String']>
  readableType?: InputMaybe<Array<Scalars['String']>>
  roles?: InputMaybe<Array<Scalars['String']>>
  tags?: InputMaybe<Array<Scalars['String']>>
  type?: InputMaybe<Array<Scalars['String']>>
}

export type ReadableSearchOrderBy = {
  direction?: InputMaybe<OrderDirection>
  field: ReadableSearchOrderField
}

export enum ReadableSearchOrderField {
  DEFAULT = 'DEFAULT',
  PUBLISHED = 'PUBLISHED',
}

/** The connection type for Readable. */
export type Readables = {
  __typename?: 'Readables'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReadablesEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Readable>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ReadablesEdge = {
  __typename?: 'ReadablesEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Readable>
}

/** Objects that can be recommended */
export type Recommendation = Company | Job | Readable

/** Autogenerated input type of RegisterB2cLead */
export type RegisterB2cLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  language?: InputMaybe<LocaleEnum>
  name?: InputMaybe<Scalars['String']>
  referer?: InputMaybe<Scalars['String']>
  source: Scalars['String']
  terms: Scalars['Boolean']
}

/** Autogenerated return type of RegisterB2cLead */
export type RegisterB2cLeadPayload = {
  __typename?: 'RegisterB2cLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** Autogenerated input type of Register */
export type RegisterInput = {
  action: EventActionEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  name?: InputMaybe<Scalars['String']>
  referer?: InputMaybe<Scalars['String']>
  uuid?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of RegisterLead */
export type RegisterLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  companyName: Scalars['String']
  email: Scalars['String']
  language?: InputMaybe<LocaleEnum>
  message?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  phone: Scalars['String']
  position: Scalars['String']
  referer?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  terms: Scalars['Boolean']
}

/** Autogenerated return type of RegisterLead */
export type RegisterLeadPayload = {
  __typename?: 'RegisterLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** Autogenerated return type of Register */
export type RegisterPayload = {
  __typename?: 'RegisterPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** Autogenerated input type of RemoveCandidate */
export type RemoveCandidateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of ResetCandidatePasswordByToken */
export type ResetCandidatePasswordByTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  params?: InputMaybe<ResetPasswordByTokenInput>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of ResetCandidatePasswordInstructions */
export type ResetCandidatePasswordInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

export type ResetPasswordByTokenInput = {
  confirmPassword: Scalars['String']
  password: Scalars['String']
  resetPasswordToken: Scalars['String']
}

/** Autogenerated input type of ResetUserPasswordByToken */
export type ResetUserPasswordByTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  params?: InputMaybe<ResetPasswordByTokenInput>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of ResetUserPasswordInstructions */
export type ResetUserPasswordInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

export type Review = {
  __typename?: 'Review'
  answer?: Maybe<ReviewAnswer>
  city?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  commentEng?: Maybe<Scalars['String']>
  commentPol?: Maybe<Scalars['String']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']>
  companyName?: Maybe<Scalars['String']>
  confirmed: Scalars['Boolean']
  contentHash: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  date?: Maybe<Scalars['String']>
  developmentProcessRating?: Maybe<Scalars['Float']>
  employmentStatus?: Maybe<Scalars['String']>
  engineeringRating?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  location?: Maybe<Scalars['String']>
  originalLang?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  professionalDevelopmentRating?: Maybe<Scalars['Float']>
  salaryRating?: Maybe<Scalars['Float']>
  satisfactionRating?: Maybe<Scalars['Float']>
  source: Scalars['String']
  summaryRating?: Maybe<Scalars['Float']>
  teamworkRating?: Maybe<Scalars['Float']>
  uuid: Scalars['ID']
}

export type ReviewAnswer = {
  __typename?: 'ReviewAnswer'
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  date: Scalars['String']
  id: Scalars['ID']
  review: Review
}

/** The connection type for Review. */
export type Reviews = {
  __typename?: 'Reviews'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewsEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Review>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ReviewsEdge = {
  __typename?: 'ReviewsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Review>
}

export type ReviewsOrderBy = {
  direction?: InputMaybe<OrderDirection>
  field: ReviewsOrderField
}

export enum ReviewsOrderField {
  CREATED = 'CREATED',
  DEFAULT = 'DEFAULT',
}

export enum RoleEnum {
  administrator = 'administrator',
  ai = 'ai',
  analyst = 'analyst',
  architect = 'architect',
  automation_tester = 'automation_tester',
  backend = 'backend',
  big_data = 'big_data',
  consultant = 'consultant',
  data = 'data',
  designer = 'designer',
  devops = 'devops',
  embedded = 'embedded',
  frontend = 'frontend',
  fullstack = 'fullstack',
  gamedev = 'gamedev',
  mobile = 'mobile',
  project_manager = 'project_manager',
  qa = 'qa',
  scrum_master = 'scrum_master',
  security = 'security',
  support = 'support',
  tech_lead = 'tech_lead',
  tester = 'tester',
}

export type Salary_Brackets = {
  max: Scalars['Int']
  min: Scalars['Int']
}

export type Salary = {
  __typename?: 'Salary'
  currency?: Maybe<Scalars['String']>
  hidden?: Maybe<Scalars['Boolean']>
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  money?: Maybe<Scalars['String']>
  timeframe?: Maybe<Scalars['String']>
}

export type SalaryParams = {
  __typename?: 'SalaryParams'
  role?: Maybe<Array<Scalars['String']>>
  technology?: Maybe<Array<Scalars['String']>>
}

export type SalaryReport = {
  __typename?: 'SalaryReport'
  b2b?: Maybe<SalarySpread>
  employment?: Maybe<SalarySpread>
}

export type SalaryReportCollection = {
  __typename?: 'SalaryReportCollection'
  all?: Maybe<SalaryReport>
  junior?: Maybe<SalaryReport>
  medium?: Maybe<SalaryReport>
  senior?: Maybe<SalaryReport>
}

export type SalarySpread = {
  __typename?: 'SalarySpread'
  spread: Array<Array<Scalars['Int']>>
  value: Array<Scalars['Int']>
}

export type SalaryStatsType = {
  __typename?: 'SalaryStatsType'
  description: Scalars['String']
  id: Scalars['ID']
  intro?: Maybe<Scalars['String']>
  lastUpdate?: Maybe<Scalars['ISO8601Date']>
  position: Scalars['String']
  reported?: Maybe<SalaryReportCollection>
  reportedTitle?: Maybe<Scalars['String']>
  salaryBrackets?: Maybe<SalaryReportCollection>
  salaryBracketsTitle?: Maybe<Scalars['String']>
  searchParams?: Maybe<SalaryParams>
  title: Scalars['String']
}

export enum SearchableEnum {
  Company = 'Company',
  HrReadable = 'HrReadable',
  Job = 'Job',
  JobWithSalary = 'JobWithSalary',
  Readable = 'Readable',
  SalaryStat = 'SalaryStat',
}

/** Autogenerated input type of SendReviewOpinion */
export type SendReviewOpinionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  opinion: Scalars['String']
  referer?: InputMaybe<Scalars['String']>
}

export enum SeniorityEnum {
  intern = 'intern',
  junior = 'junior',
  lead = 'lead',
  medium = 'medium',
  senior = 'senior',
}

/** Autogenerated input type of Show */
export type ShowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  jobId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of Show */
export type ShowPayload = {
  __typename?: 'ShowPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** Autogenerated input type of SignInCandidate */
export type SignInCandidateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<Auth_Provider_Credentials>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of SignInOauth */
export type SignInOauthInput = {
  auth: O_Auth_Input
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of SignInUser */
export type SignInUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<Auth_Provider_Credentials>
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

export type Signable = Admin | Candidate | User

export type StructuredData = {
  __typename?: 'StructuredData'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  itemId: Scalars['Int']
  itemType: Scalars['String']
  jsonLd: Scalars['JSON']
}

export type SuccessErrors = {
  __typename?: 'SuccessErrors'
  errors?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Tech = {
  __typename?: 'Tech'
  icon?: Maybe<Image>
  name?: Maybe<Scalars['String']>
}

export type TechUsage = {
  __typename?: 'TechUsage'
  lang?: Maybe<Array<Tech>>
  platform?: Maybe<Array<Tech>>
  tool?: Maybe<Array<Tech>>
}

/** Autogenerated input type of ToggleReactionReadable */
export type ToggleReactionReadableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  readableId: Scalars['ID']
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ToggleReactionReadable */
export type ToggleReactionReadablePayload = {
  __typename?: 'ToggleReactionReadablePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Tools = {
  __typename?: 'Tools'
  additionalMonitor?: Maybe<Scalars['Boolean']>
  container?: Maybe<Scalars['Boolean']>
  freeSelection?: Maybe<Scalars['Boolean']>
  headphones?: Maybe<Scalars['Boolean']>
  laptop?: Maybe<Scalars['Boolean']>
  linux?: Maybe<Scalars['Boolean']>
  osx?: Maybe<Scalars['Boolean']>
  pc?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['Boolean']>
  windows?: Maybe<Scalars['Boolean']>
}

export type TopTech = {
  __typename?: 'TopTech'
  description?: Maybe<Scalars['String']>
  ratingsCount?: Maybe<Scalars['Int']>
  stars1Title?: Maybe<Scalars['String']>
  stars1Value?: Maybe<Scalars['Float']>
  stars2Title?: Maybe<Scalars['String']>
  stars2Value?: Maybe<Scalars['Float']>
  stars3Title?: Maybe<Scalars['String']>
  stars3Value?: Maybe<Scalars['Float']>
  tte?: Maybe<Scalars['Boolean']>
}

export type Translate = {
  __typename?: 'Translate'
  en?: Maybe<Scalars['String']>
  pl?: Maybe<Scalars['String']>
}

export type Translations = {
  __typename?: 'Translations'
  key?: Maybe<Scalars['String']>
  titles?: Maybe<Translate>
  value?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateMinSalary */
export type UpdateMinSalaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  minSalary: Scalars['Int']
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of UpdateReview */
export type UpdateReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  comment?: InputMaybe<Scalars['String']>
  developmentProcessRating?: InputMaybe<Scalars['Float']>
  editToken?: InputMaybe<Scalars['String']>
  employmentStatus?: InputMaybe<Scalars['Boolean']>
  engineeringRating?: InputMaybe<Scalars['Float']>
  id: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  professionalDevelopmentRating?: InputMaybe<Scalars['Float']>
  referer?: InputMaybe<Scalars['String']>
  salaryRating?: InputMaybe<Scalars['Float']>
  satisfactionRating?: InputMaybe<Scalars['Float']>
  teamworkRating?: InputMaybe<Scalars['Float']>
}

/** Autogenerated input type of UpvoteComment */
export type UpvoteCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  commentId: Scalars['ID']
  language?: InputMaybe<LocaleEnum>
  referer?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpvoteComment */
export type UpvoteCommentPayload = {
  __typename?: 'UpvoteCommentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type User = {
  __typename?: 'User'
  avatar?: Maybe<Image>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type AuthorQueryVariables = Exact<{
  authorId: Scalars['ID']
}>

export type AuthorQuery = {
  __typename?: 'Query'
  author?: {
    __typename?: 'Author'
    id: string
    name?: string | null
    position?: string | null
    bio?: string | null
    github?: string | null
    linkedin?: string | null
    twitter?: string | null
    www?: string | null
    kind?: string | null
    company?: string | null
    photo?: { __typename?: 'Image'; url?: string | null } | null
  } | null
}

export type BestAuthorsQueryVariables = Exact<{ [key: string]: never }>

export type BestAuthorsQuery = {
  __typename?: 'Query'
  bestAuthors?: Array<{
    __typename?: 'Author'
    name?: string | null
    position?: string | null
    photo?: { __typename?: 'Image'; url?: string | null } | null
  }> | null
}

export type GetReadablesTermsQueryVariables = Exact<{
  language: LocaleEnum
}>

export type GetReadablesTermsQuery = {
  __typename?: 'Query'
  getReadablesTerms?: any | null
}

export type CommentsQueryVariables = Exact<{
  readableId: Scalars['ID']
}>

export type CommentsQuery = {
  __typename?: 'Query'
  comments: {
    __typename?: 'Comments'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Comment'
      hidden?: boolean | null
      htmlContent?: string | null
      id: string
      markdownContent?: string | null
      parentCommentId?: string | null
      score?: number | null
      creator?: {
        __typename?: 'Creator'
        displayedName?: string | null
        avatar?: { __typename?: 'Image'; url?: string | null } | null
      } | null
    } | null> | null
  }
}

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment?: {
    __typename?: 'CreateCommentPayload'
    errors?: any | null
    comment?: {
      __typename?: 'Comment'
      hidden?: boolean | null
      htmlContent?: string | null
      id: string
      markdownContent?: string | null
      parentCommentId?: string | null
      score?: number | null
      creator?: {
        __typename?: 'Creator'
        displayedName?: string | null
        avatar?: { __typename?: 'Image'; url?: string | null } | null
      } | null
    } | null
  } | null
}

export type ReadableQueryVariables = Exact<{
  readableId: Scalars['ID']
  readableType?: InputMaybe<Scalars['String']>
  language?: InputMaybe<LocaleEnum>
  viewToken?: InputMaybe<Scalars['String']>
}>

export type ReadableQuery = {
  __typename?: 'Query'
  readable?: {
    __typename?: 'Readable'
    content?: string | null
    experiences?: string | null
    hasAuthor?: boolean | null
    id: string
    intro?: string | null
    positions?: string | null
    slug?: string | null
    readableId: string
    readableType: string
    tier?: string | null
    mainTag?: string | null
    title?: string | null
    language?: string | null
    thumbsup?: number | null
    thumbsupped?: boolean | null
    wordCount?: string | null
    publishedAt?: any | null
    forIt?: boolean | null
    forHr?: boolean | null
    updated?: any | null
    author?: {
      __typename?: 'Author'
      name?: string | null
      company?: string | null
      id: string
      kind?: string | null
      position?: string | null
      companyLogo?: { __typename?: 'Image'; url?: string | null } | null
      photo?: { __typename?: 'Image'; url?: string | null } | null
    } | null
    company?: {
      __typename?: 'Company'
      name: string
      id: string
      logo?: { __typename?: 'Image'; url?: string | null } | null
    } | null
    categories?: Array<{
      __typename?: 'Translations'
      key?: string | null
      titles?: {
        __typename?: 'Translate'
        pl?: string | null
        en?: string | null
      } | null
    }> | null
    cover?: { __typename?: 'Image'; url?: string | null } | null
    roles?: Array<{
      __typename?: 'Translations'
      key?: string | null
      titles?: {
        __typename?: 'Translate'
        pl?: string | null
        en?: string | null
      } | null
    }> | null
    tags?: Array<{
      __typename?: 'Translations'
      key?: string | null
      titles?: {
        __typename?: 'Translate'
        pl?: string | null
        en?: string | null
      } | null
    }> | null
  } | null
}

export type RecoReadableFragment = {
  __typename?: 'Readable'
  experiences?: string | null
  hasAuthor?: boolean | null
  id: string
  positions?: string | null
  readableId: string
  readableType: string
  slug?: string | null
  tier?: string | null
  mainTag?: string | null
  title?: string | null
  language?: string | null
  thumbsup?: number | null
  thumbsupped?: boolean | null
  wordCount?: string | null
  publishedAt?: any | null
  author?: {
    __typename?: 'Author'
    name?: string | null
    company?: string | null
    bio?: string | null
    id: string
    kind?: string | null
    position?: string | null
    companyLogo?: { __typename?: 'Image'; url?: string | null } | null
    photo?: { __typename?: 'Image'; url?: string | null } | null
  } | null
  company?: {
    __typename?: 'Company'
    name: string
    id: string
    logo?: { __typename?: 'Image'; url?: string | null } | null
  } | null
  categories?: Array<{
    __typename?: 'Translations'
    key?: string | null
    titles?: {
      __typename?: 'Translate'
      pl?: string | null
      en?: string | null
    } | null
  }> | null
  cover?: { __typename?: 'Image'; url?: string | null } | null
  roles?: Array<{
    __typename?: 'Translations'
    key?: string | null
    titles?: {
      __typename?: 'Translate'
      pl?: string | null
      en?: string | null
    } | null
  }> | null
  tags?: Array<{
    __typename?: 'Translations'
    key?: string | null
    titles?: {
      __typename?: 'Translate'
      pl?: string | null
      en?: string | null
    } | null
  }> | null
}

export type RecommendationsReadablesQueryVariables = Exact<{
  language: LocaleEnum
  resourceId: Scalars['ID']
  resourceType: SearchableEnum
  returnType: SearchableEnum
  perPage: Scalars['Int']
  page: Scalars['Int']
}>

export type RecommendationsReadablesQuery = {
  __typename?: 'Query'
  recommendations?: Array<
    | { __typename?: 'Company' }
    | { __typename?: 'Job' }
    | {
        __typename?: 'Readable'
        experiences?: string | null
        hasAuthor?: boolean | null
        id: string
        positions?: string | null
        readableId: string
        readableType: string
        slug?: string | null
        tier?: string | null
        mainTag?: string | null
        title?: string | null
        language?: string | null
        thumbsup?: number | null
        thumbsupped?: boolean | null
        wordCount?: string | null
        publishedAt?: any | null
        author?: {
          __typename?: 'Author'
          name?: string | null
          company?: string | null
          bio?: string | null
          id: string
          kind?: string | null
          position?: string | null
          companyLogo?: { __typename?: 'Image'; url?: string | null } | null
          photo?: { __typename?: 'Image'; url?: string | null } | null
        } | null
        company?: {
          __typename?: 'Company'
          name: string
          id: string
          logo?: { __typename?: 'Image'; url?: string | null } | null
        } | null
        categories?: Array<{
          __typename?: 'Translations'
          key?: string | null
          titles?: {
            __typename?: 'Translate'
            pl?: string | null
            en?: string | null
          } | null
        }> | null
        cover?: { __typename?: 'Image'; url?: string | null } | null
        roles?: Array<{
          __typename?: 'Translations'
          key?: string | null
          titles?: {
            __typename?: 'Translate'
            pl?: string | null
            en?: string | null
          } | null
        }> | null
        tags?: Array<{
          __typename?: 'Translations'
          key?: string | null
          titles?: {
            __typename?: 'Translate'
            pl?: string | null
            en?: string | null
          } | null
        }> | null
      }
  > | null
}

export type SearchReadablesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<ReadableFilters>
  language?: InputMaybe<LocaleEnum>
}>

export type SearchReadablesQuery = {
  __typename?: 'Query'
  searchReadables: {
    __typename?: 'Readables'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Readable'
      readableId: string
      slug?: string | null
      readableType: string
      title?: string | null
      hasAuthor?: boolean | null
      wordCount?: string | null
      publishedAt?: any | null
      updated?: any | null
      company?: {
        __typename?: 'Company'
        name: string
        id: string
        logo?: { __typename?: 'Image'; url?: string | null } | null
      } | null
      author?: {
        __typename?: 'Author'
        name?: string | null
        position?: string | null
        photo?: { __typename?: 'Image'; url?: string | null } | null
        companyLogo?: { __typename?: 'Image'; url?: string | null } | null
      } | null
      cover?: { __typename?: 'Image'; url?: string | null } | null
    } | null> | null
  }
}

export type ToggleReactionReadableMutationVariables = Exact<{
  input: ToggleReactionReadableInput
}>

export type ToggleReactionReadableMutation = {
  __typename?: 'Mutation'
  toggleReactionReadable?: {
    __typename?: 'ToggleReactionReadablePayload'
    success: boolean
  } | null
}

export type CreateCandidateMutationVariables = Exact<{
  input: CreateCandidateInput
}>

export type CreateCandidateMutation = {
  __typename?: 'Mutation'
  createCandidate?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type ResetCandidatePasswordInstructionsMutationVariables = Exact<{
  input: ResetCandidatePasswordInstructionsInput
}>

export type ResetCandidatePasswordInstructionsMutation = {
  __typename?: 'Mutation'
  resetCandidatePasswordInstructions?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type SigninCandidateMutationVariables = Exact<{
  input: SignInCandidateInput
}>

export type SigninCandidateMutation = {
  __typename?: 'Mutation'
  signinCandidate?: {
    __typename?: 'LoginUser'
    token?: string | null
    errors?: string | null
    user?: { __typename?: 'User'; email?: string | null } | null
  } | null
}

export type SigninOauthMutationVariables = Exact<{
  input: SignInOauthInput
}>

export type SigninOauthMutation = {
  __typename?: 'Mutation'
  signinOauth?: {
    __typename?: 'LoginUser'
    token?: string | null
    user?: {
      __typename?: 'User'
      email?: string | null
      name?: string | null
    } | null
  } | null
}

export type UpdateMinSalaryMutationVariables = Exact<{
  input: UpdateMinSalaryInput
}>

export type UpdateMinSalaryMutation = {
  __typename?: 'Mutation'
  updateMinSalary?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type ClaimCompanyMutationVariables = Exact<{
  input: ClaimCompanyInput
}>

export type ClaimCompanyMutation = {
  __typename?: 'Mutation'
  claimCompany?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type SearchCompaniesListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<CompanyFilters>
  order?: InputMaybe<CompanySearchOrderBy>
  language?: InputMaybe<LocaleEnum>
}>

export type SearchCompaniesListQuery = {
  __typename?: 'Query'
  searchCompanies: {
    __typename?: 'Companies'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Company'
      id: string
      name: string
      activeOffersCount?: number | null
      skills?: Array<string> | null
      visible?: boolean | null
      logo?: { __typename?: 'Image'; url?: string | null } | null
      locations: Array<{
        __typename?: 'Location'
        cityEn?: string | null
        cityPl?: string | null
      }>
    } | null> | null
  }
}

export type CompanyQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<LocaleEnum>
  viewToken?: InputMaybe<Scalars['String']>
}>

export type CompanyQuery = {
  __typename?: 'Query'
  company?: {
    __typename?: 'Company'
    activeOffersCount?: number | null
    address?: string | null
    sorting?: string | null
    currentLayout?: string | null
    employees?: string | null
    facebook?: string | null
    github?: string | null
    history?: string | null
    id: string
    instagram?: string | null
    linkedin?: string | null
    locale?: string | null
    name: string
    postalCode?: string | null
    salaryBracketsHidden: boolean
    skills?: Array<string> | null
    themeColor?: string | null
    twitter?: string | null
    website?: string | null
    benefits: Array<{
      __typename?: 'Benefit'
      category?: string | null
      localizedName?: string | null
      name?: string | null
      owner?: boolean | null
    }>
    coverPhoto?: { __typename?: 'Image'; url?: string | null } | null
    galleryAssets?: Array<{
      __typename?: 'GalleryItem'
      assetUrl: string
      description?: string | null
      thumbnailUrl: string
      type: GalleryItemTypeEnum
    }> | null
    employeeQuotes: Array<{
      __typename?: 'Employee'
      name?: string | null
      quote?: string | null
      position?: string | null
      linkedin?: string | null
      twitter?: string | null
      github?: string | null
      photo?: { __typename?: 'Image'; url?: string | null } | null
    }>
    environment: {
      __typename?: 'Environment'
      paidVacations?: string | null
      remotePossible?: number | null
    }
    offices: Array<{
      __typename?: 'Office'
      address?: string | null
      location: {
        __typename?: 'Location'
        cityPl?: string | null
        cityEn?: string | null
      }
    }>
    techUsage?: {
      __typename?: 'TechUsage'
      lang?: Array<{
        __typename?: 'Tech'
        name?: string | null
        icon?: { __typename?: 'Image'; url?: string | null } | null
      }> | null
      platform?: Array<{
        __typename?: 'Tech'
        name?: string | null
        icon?: { __typename?: 'Image'; url?: string | null } | null
      }> | null
      tool?: Array<{
        __typename?: 'Tech'
        name?: string | null
        icon?: { __typename?: 'Image'; url?: string | null } | null
      }> | null
    } | null
    howWeCode: {
      __typename?: 'HowWeCode'
      bdd?: boolean | null
      codeMetrics?: boolean | null
      codeReview?: boolean | null
      knowledgeDatabase?: boolean | null
      pairProgramming?: boolean | null
      staticCodeAnalysis?: boolean | null
      styleGuide?: boolean | null
      tdd?: boolean | null
      versionControlDescription?: string | null
    }
    howWeManage: {
      __typename?: 'HowWeManage'
      architecturalDecisions?: string | null
      canSwitchProjects?: boolean | null
      methodology?: string | null
      projectSoftware?: string | null
      teamDesign?: number | null
      teamDev?: number | null
      teamPm?: number | null
      teamQa?: number | null
      techDecisions?: string | null
    }
    howWeTest: {
      __typename?: 'HowWeTest'
      automatedTesting?: boolean | null
      ci?: boolean | null
      integration?: boolean | null
      manualTesting?: boolean | null
      performance?: boolean | null
      security?: boolean | null
      system?: boolean | null
      unit?: boolean | null
    }
    jobCover?: { __typename?: 'Image'; url?: string | null } | null
    locations: Array<{
      __typename?: 'Location'
      cityPl?: string | null
      cityEn?: string | null
    }>
    logo?: { __typename?: 'Image'; url?: string | null } | null
    people: Array<{
      __typename?: 'Person'
      name?: string | null
      insight?: string | null
      position?: string | null
      photo?: { __typename?: 'Image'; url?: string | null } | null
    }>
    tools: {
      __typename?: 'Tools'
      additionalMonitor?: boolean | null
      container?: boolean | null
      freeSelection?: boolean | null
      headphones?: boolean | null
      laptop?: boolean | null
      linux?: boolean | null
      osx?: boolean | null
      pc?: boolean | null
      phone?: boolean | null
      windows?: boolean | null
    }
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?:
    | { __typename?: 'Admin' }
    | {
        __typename?: 'Candidate'
        email?: string | null
        likedJobs?: Array<string> | null
        rejectedJobs?: Array<string> | null
        employmentType?: Array<string> | null
        expectedSalary?: number | null
        linkedin?: string | null
        cvFileName?: string | null
        location?: Array<string> | null
        minSalary?: number | null
        name?: string | null
        otherTech?: Array<string> | null
        phone?: string | null
        relocation?: boolean | null
        remote?: boolean | null
        role?: Array<string> | null
        seniority?: string | null
        tech?: Array<string> | null
        tech1?: string | null
        tech1Experience?: string | null
        tech2?: string | null
        tech2Experience?: string | null
        avatar?: { __typename?: 'Image'; url?: string | null } | null
      }
    | {
        __typename?: 'User'
        email?: string | null
        name?: string | null
        avatar?: { __typename?: 'Image'; url?: string | null } | null
      }
    | null
}

export type MetadataQueryVariables = Exact<{
  companyFilters?: InputMaybe<CompanyFilters>
  id?: InputMaybe<Scalars['ID']>
  jobFilters?: InputMaybe<JobFilters>
  language: LocaleEnum
  pageType: PageEnum
  readableFilters?: InputMaybe<ReadableFilters>
  url?: InputMaybe<Scalars['String']>
}>

export type MetadataQuery = {
  __typename?: 'Query'
  metadata?: {
    __typename?: 'Metadata'
    canonicalUrl?: string | null
    description?: string | null
    hreflangEnUrl?: string | null
    hreflangPlUrl?: string | null
    imageUrl?: string | null
    noindex?: boolean | null
    title: string
    jsonLd?: any | null
    breadcrumbJsonLd?: any | null
    header?: string | null
    subheader?: string | null
  } | null
}

export type ApplyCvMutationVariables = Exact<{
  input: ApplyCvInput
}>

export type ApplyCvMutation = {
  __typename?: 'Mutation'
  applyCv?: {
    __typename?: 'ApplyCvPayload'
    clientMutationId?: string | null
    errors?: any | null
    poposal?: { __typename?: 'Proposal'; id: string } | null
  } | null
}

export type ApplyExternalMutationVariables = Exact<{
  input: ApplyExternalInput
}>

export type ApplyExternalMutation = {
  __typename?: 'Mutation'
  applyExternal?: {
    __typename?: 'ApplyExternalPayload'
    applyUrl?: string | null
  } | null
}

export type ApplyWithProfileMutationVariables = Exact<{
  input: ApplyWithProfileInput
}>

export type ApplyWithProfileMutation = {
  __typename?: 'Mutation'
  applyWithProfile?: {
    __typename?: 'ApplyWithProfilePayload'
    clientMutationId?: string | null
    errors?: any | null
    proposal?: { __typename?: 'Proposal'; id: string } | null
  } | null
}

export type HideOfferMutationVariables = Exact<{
  input: HideInput
}>

export type HideOfferMutation = {
  __typename?: 'Mutation'
  hideOffer?: { __typename?: 'HidePayload'; success: boolean } | null
}

export type JobQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  viewToken?: InputMaybe<Scalars['String']>
}>

export type JobQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    applyViaProfile?: boolean | null
    contractB2b?: boolean | null
    contractEmployment?: boolean | null
    contractOther?: boolean | null
    details?: string | null
    employmentType: string
    endsAt?: any | null
    experienceLevel: string
    minExperienceInYears?: number | null
    hiddenBrackets?: boolean | null
    highlight?: boolean | null
    mainTechnology?: string | null
    matchingUserBrackets: boolean
    offer?: string | null
    position?: string | null
    publishedAt?: any | null
    recruitmentProcess?: Array<string> | null
    remote?: boolean | null
    requirements?: string | null
    roles?: Array<string> | null
    showSalary: boolean
    state?: string | null
    technologyTags?: Array<string> | null
    redirectTo?: string | null
    workFrom?: Array<string> | null
    workModes?: Array<string> | null
    locale: string
    id: string
    internalApplication: boolean
    topBanner?: { __typename?: 'Image'; url?: string | null } | null
    benefits?: Array<{
      __typename?: 'Benefit'
      category?: string | null
      localizedName?: string | null
      name?: string | null
      owner?: boolean | null
    }> | null
    consents?: Array<{
      __typename?: 'Consent'
      id: string
      required: boolean
      text: string
    }> | null
    company: {
      __typename?: 'Company'
      id: string
      history?: string | null
      activeOffersCount?: number | null
      address?: string | null
      managed?: boolean | null
      employees?: string | null
      themeColor?: string | null
      published?: boolean | null
      currentLayout?: string | null
      name: string
      environment: {
        __typename?: 'Environment'
        paidVacations?: string | null
        remotePossible?: number | null
      }
      benefits: Array<{
        __typename?: 'Benefit'
        category?: string | null
        localizedName?: string | null
        name?: string | null
        owner?: boolean | null
      }>
      coverPhoto?: { __typename?: 'Image'; url?: string | null } | null
      jobCover?: { __typename?: 'Image'; url?: string | null } | null
      logo?: { __typename?: 'Image'; url?: string | null } | null
    }
    environment: {
      __typename?: 'Environment'
      paidVacations?: string | null
      remotePossible?: number | null
    }
    locations?: Array<{
      __typename?: 'JobLocation'
      address?: string | null
      location?: {
        __typename?: 'Location'
        cityPl?: string | null
        cityEn?: string | null
      } | null
    }> | null
    howWeCode: {
      __typename?: 'HowWeCode'
      bdd?: boolean | null
      codeMetrics?: boolean | null
      codeReview?: boolean | null
      knowledgeDatabase?: boolean | null
      pairProgramming?: boolean | null
      staticCodeAnalysis?: boolean | null
      styleGuide?: boolean | null
      tdd?: boolean | null
      versionControlDescription?: string | null
    }
    howWeManage: {
      __typename?: 'HowWeManage'
      architecturalDecisions?: string | null
      canSwitchProjects?: boolean | null
      methodology?: string | null
      projectSoftware?: string | null
      teamDesign?: number | null
      teamDev?: number | null
      teamPm?: number | null
      teamQa?: number | null
      techDecisions?: string | null
    }
    howWeTest: {
      __typename?: 'HowWeTest'
      automatedTesting?: boolean | null
      ci?: boolean | null
      integration?: boolean | null
      manualTesting?: boolean | null
      performance?: boolean | null
      security?: boolean | null
      system?: boolean | null
      unit?: boolean | null
    }
    employmentSalary?: {
      __typename?: 'Salary'
      currency?: string | null
      hidden?: boolean | null
      maxValue?: string | null
      minValue?: string | null
      money?: string | null
      timeframe?: string | null
    } | null
    b2bSalary?: {
      __typename?: 'Salary'
      currency?: string | null
      hidden?: boolean | null
      maxValue?: string | null
      minValue?: string | null
      money?: string | null
      timeframe?: string | null
    } | null
    otherSalary?: {
      __typename?: 'Salary'
      currency?: string | null
      hidden?: boolean | null
      maxValue?: string | null
      minValue?: string | null
      money?: string | null
      timeframe?: string | null
    } | null
    tools: {
      __typename?: 'Tools'
      additionalMonitor?: boolean | null
      container?: boolean | null
      freeSelection?: boolean | null
      headphones?: boolean | null
      laptop?: boolean | null
      linux?: boolean | null
      osx?: boolean | null
      pc?: boolean | null
      phone?: boolean | null
      windows?: boolean | null
    }
  } | null
}

export type JobSalaryMatchQueryVariables = Exact<{
  minSalary: Scalars['Int']
  jobId: Scalars['ID']
}>

export type JobSalaryMatchQuery = {
  __typename?: 'Query'
  jobSalaryMatch: {
    __typename?: 'JobSalaryMatch'
    matchingUserBrackets: boolean
  }
}

export type SearchJobsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<JobFilters>
  order?: InputMaybe<JobsSearchOrderBy>
  language?: InputMaybe<LocaleEnum>
  boostWhere?: InputMaybe<BoostWhere>
  exclude?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type SearchJobsQuery = {
  __typename?: 'Query'
  searchJobs: {
    __typename?: 'Jobs'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Job'
      id: string
      redirectTo?: string | null
      highlight?: boolean | null
      city?: string | null
      experienceLevel: string
      employmentType: string
      hiddenBrackets?: boolean | null
      matchingUserBrackets: boolean
      position?: string | null
      remote?: boolean | null
      showSalary: boolean
      technologyTags?: Array<string> | null
      contractB2b?: boolean | null
      contractEmployment?: boolean | null
      contractOther?: boolean | null
      locale: string
      company: {
        __typename?: 'Company'
        name: string
        logo?: { __typename?: 'Image'; url?: string | null } | null
      }
      denominatedSalaryLong: {
        __typename?: 'Salary'
        money?: string | null
        currency?: string | null
        hidden?: boolean | null
      }
      environment: {
        __typename?: 'Environment'
        remotePossible?: number | null
      }
    } | null> | null
  }
}

export type JobsLocationsQueryVariables = Exact<{
  amount?: InputMaybe<Scalars['Int']>
  language: LocaleEnum
}>

export type JobsLocationsQuery = {
  __typename?: 'Query'
  jobsLocations?: Array<string> | null
}

export type LikeOfferMutationVariables = Exact<{
  input: LikeOfferInput
}>

export type LikeOfferMutation = {
  __typename?: 'Mutation'
  likeOffer?: { __typename?: 'LikeOfferPayload'; success: boolean } | null
}

export type ShowOfferMutationVariables = Exact<{
  input: ShowInput
}>

export type ShowOfferMutation = {
  __typename?: 'Mutation'
  showOffer?: { __typename?: 'ShowPayload'; success: boolean } | null
}

export type DislikeOfferMutationVariables = Exact<{
  input: DislikeInput
}>

export type DislikeOfferMutation = {
  __typename?: 'Mutation'
  dislikeOffer?: { __typename?: 'DislikePayload'; success: boolean } | null
}

export type RecoFragment = {
  __typename?: 'Job'
  id: string
  highlight?: boolean | null
  city?: string | null
  experienceLevel: string
  employmentType: string
  hiddenBrackets?: boolean | null
  matchingUserBrackets: boolean
  position?: string | null
  remote?: boolean | null
  showSalary: boolean
  technologyTags?: Array<string> | null
  contractB2b?: boolean | null
  contractEmployment?: boolean | null
  contractOther?: boolean | null
  locale: string
  company: {
    __typename?: 'Company'
    name: string
    verified: boolean
    summaryRating?: number | null
    logo?: { __typename?: 'Image'; url?: string | null } | null
  }
  denominatedSalaryLong: {
    __typename?: 'Salary'
    money?: string | null
    currency?: string | null
    hidden?: boolean | null
  }
  environment: { __typename?: 'Environment'; remotePossible?: number | null }
}

export type RecommendationsQueryVariables = Exact<{
  language: LocaleEnum
  resourceId: Scalars['ID']
  resourceType: SearchableEnum
  returnType: SearchableEnum
  perPage: Scalars['Int']
  page: Scalars['Int']
  boostWhere?: InputMaybe<BoostWhere>
  minResults?: InputMaybe<Scalars['Int']>
}>

export type RecommendationsQuery = {
  __typename?: 'Query'
  recommendations?: Array<
    | { __typename?: 'Company' }
    | {
        __typename?: 'Job'
        id: string
        highlight?: boolean | null
        city?: string | null
        experienceLevel: string
        employmentType: string
        hiddenBrackets?: boolean | null
        matchingUserBrackets: boolean
        position?: string | null
        remote?: boolean | null
        showSalary: boolean
        technologyTags?: Array<string> | null
        contractB2b?: boolean | null
        contractEmployment?: boolean | null
        contractOther?: boolean | null
        locale: string
        company: {
          __typename?: 'Company'
          name: string
          verified: boolean
          summaryRating?: number | null
          logo?: { __typename?: 'Image'; url?: string | null } | null
        }
        denominatedSalaryLong: {
          __typename?: 'Salary'
          money?: string | null
          currency?: string | null
          hidden?: boolean | null
        }
        environment: {
          __typename?: 'Environment'
          remotePossible?: number | null
        }
      }
    | { __typename?: 'Readable' }
  > | null
}

export type RegisterB2cLeadMutationVariables = Exact<{
  input: RegisterB2cLeadInput
}>

export type RegisterB2cLeadMutation = {
  __typename?: 'Mutation'
  registerB2cLead?: {
    __typename?: 'RegisterB2cLeadPayload'
    success: boolean
  } | null
}

export type RegisterEventMutationVariables = Exact<{
  input: RegisterInput
}>

export type RegisterEventMutation = {
  __typename?: 'Mutation'
  registerEvent?: { __typename?: 'RegisterPayload'; success: boolean } | null
}

export type RegisterLeadMutationVariables = Exact<{
  input: RegisterLeadInput
}>

export type RegisterLeadMutation = {
  __typename?: 'Mutation'
  registerLead?: { __typename?: 'RegisterLeadPayload'; success: boolean } | null
}

export type ResetCandidatePasswordByTokenMutationVariables = Exact<{
  input: ResetCandidatePasswordByTokenInput
}>

export type ResetCandidatePasswordByTokenMutation = {
  __typename?: 'Mutation'
  resetCandidatePasswordByToken?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type SalaryReportFieldsFragment = {
  __typename?: 'SalaryReport'
  employment?: {
    __typename?: 'SalarySpread'
    value: Array<number>
    spread: Array<Array<number>>
  } | null
  b2b?: {
    __typename?: 'SalarySpread'
    value: Array<number>
    spread: Array<Array<number>>
  } | null
}

export type SalaryStatsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<LocaleEnum>
}>

export type SalaryStatsQuery = {
  __typename?: 'Query'
  salaryStats?: {
    __typename?: 'SalaryStatsType'
    id: string
    title: string
    intro?: string | null
    description: string
    position: string
    lastUpdate?: any | null
    reportedTitle?: string | null
    salaryBracketsTitle?: string | null
    reported?: {
      __typename?: 'SalaryReportCollection'
      all?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      junior?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      medium?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      senior?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
    } | null
    salaryBrackets?: {
      __typename?: 'SalaryReportCollection'
      all?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      junior?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      medium?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
      senior?: {
        __typename?: 'SalaryReport'
        employment?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
        b2b?: {
          __typename?: 'SalarySpread'
          value: Array<number>
          spread: Array<Array<number>>
        } | null
      } | null
    } | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type ResetUserPasswordByTokenMutationVariables = Exact<{
  input: ResetUserPasswordByTokenInput
}>

export type ResetUserPasswordByTokenMutation = {
  __typename?: 'Mutation'
  resetUserPasswordByToken?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type ResetUserPasswordInstructionsMutationVariables = Exact<{
  input: ResetUserPasswordInstructionsInput
}>

export type ResetUserPasswordInstructionsMutation = {
  __typename?: 'Mutation'
  resetUserPasswordInstructions?: {
    __typename?: 'SuccessErrors'
    success: boolean
    errors?: string | null
  } | null
}

export type SigninUserMutationVariables = Exact<{
  input: SignInUserInput
}>

export type SigninUserMutation = {
  __typename?: 'Mutation'
  signinUser?: {
    __typename?: 'LoginUser'
    token?: string | null
    errors?: string | null
    user?: { __typename?: 'User'; email?: string | null } | null
  } | null
}

export const RecoReadableFragmentDoc = gql`
  fragment RecoReadable on Readable {
    author {
      name
      company
      companyLogo {
        url(style: "list")
      }
      bio
      id
      kind
      position
      photo {
        url(style: "small_square")
      }
    }
    company {
      name
      id
      logo {
        url(style: "list")
      }
    }
    categories {
      key
      titles {
        pl
        en
      }
    }
    cover {
      url(style: "large_thumb")
    }
    experiences
    hasAuthor
    id
    positions
    readableId
    readableType
    slug
    roles {
      key
      titles {
        pl
        en
      }
    }
    tags {
      key
      titles {
        pl
        en
      }
    }
    tier
    mainTag
    title
    language
    thumbsup
    thumbsupped
    wordCount
    publishedAt
  }
`
export const RecoFragmentDoc = gql`
  fragment Reco on Job {
    id
    company {
      name
      verified
      summaryRating
      logo {
        url(style: "list")
      }
    }
    denominatedSalaryLong {
      money
      currency
      hidden
    }
    environment {
      remotePossible
    }
    highlight
    city
    experienceLevel
    employmentType
    hiddenBrackets
    matchingUserBrackets
    position
    remote
    showSalary
    technologyTags
    contractB2b
    contractEmployment
    contractOther
    locale
  }
`
export const SalaryReportFieldsFragmentDoc = gql`
  fragment SalaryReportFields on SalaryReport {
    employment {
      value
      spread
    }
    b2b {
      value
      spread
    }
  }
`
export const AuthorDocument = gql`
  query author($authorId: ID!) {
    author(id: $authorId) {
      id
      photo {
        url
      }
      name
      position
      bio
      github
      linkedin
      twitter
      www
      kind
      company
    }
  }
`

/**
 * __useAuthorQuery__
 *
 * To run a query within a React component, call `useAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorQuery({
 *   variables: {
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function useAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<AuthorQuery, AuthorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthorQuery, AuthorQueryVariables>(
    AuthorDocument,
    options
  )
}
export function useAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthorQuery, AuthorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthorQuery, AuthorQueryVariables>(
    AuthorDocument,
    options
  )
}
export type AuthorQueryHookResult = ReturnType<typeof useAuthorQuery>
export type AuthorLazyQueryHookResult = ReturnType<typeof useAuthorLazyQuery>
export type AuthorQueryResult = Apollo.QueryResult<
  AuthorQuery,
  AuthorQueryVariables
>
export const BestAuthorsDocument = gql`
  query bestAuthors {
    bestAuthors {
      photo {
        url
      }
      name
      position
    }
  }
`

/**
 * __useBestAuthorsQuery__
 *
 * To run a query within a React component, call `useBestAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBestAuthorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BestAuthorsQuery,
    BestAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BestAuthorsQuery, BestAuthorsQueryVariables>(
    BestAuthorsDocument,
    options
  )
}
export function useBestAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BestAuthorsQuery,
    BestAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BestAuthorsQuery, BestAuthorsQueryVariables>(
    BestAuthorsDocument,
    options
  )
}
export type BestAuthorsQueryHookResult = ReturnType<typeof useBestAuthorsQuery>
export type BestAuthorsLazyQueryHookResult = ReturnType<
  typeof useBestAuthorsLazyQuery
>
export type BestAuthorsQueryResult = Apollo.QueryResult<
  BestAuthorsQuery,
  BestAuthorsQueryVariables
>
export const GetReadablesTermsDocument = gql`
  query getReadablesTerms($language: LocaleEnum!) {
    getReadablesTerms(language: $language)
  }
`

/**
 * __useGetReadablesTermsQuery__
 *
 * To run a query within a React component, call `useGetReadablesTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadablesTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadablesTermsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetReadablesTermsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReadablesTermsQuery,
    GetReadablesTermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetReadablesTermsQuery,
    GetReadablesTermsQueryVariables
  >(GetReadablesTermsDocument, options)
}
export function useGetReadablesTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReadablesTermsQuery,
    GetReadablesTermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetReadablesTermsQuery,
    GetReadablesTermsQueryVariables
  >(GetReadablesTermsDocument, options)
}
export type GetReadablesTermsQueryHookResult = ReturnType<
  typeof useGetReadablesTermsQuery
>
export type GetReadablesTermsLazyQueryHookResult = ReturnType<
  typeof useGetReadablesTermsLazyQuery
>
export type GetReadablesTermsQueryResult = Apollo.QueryResult<
  GetReadablesTermsQuery,
  GetReadablesTermsQueryVariables
>
export const CommentsDocument = gql`
  query comments($readableId: ID!) {
    comments(readableId: $readableId) {
      totalCount
      nodes {
        hidden
        htmlContent
        id
        markdownContent
        parentCommentId
        score
        creator {
          avatar {
            url
          }
          displayedName
        }
      }
    }
  }
`

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  )
}
export function useCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentsQuery,
    CommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  )
}
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>
export type CommentsLazyQueryHookResult = ReturnType<
  typeof useCommentsLazyQuery
>
export type CommentsQueryResult = Apollo.QueryResult<
  CommentsQuery,
  CommentsQueryVariables
>
export const CreateCommentDocument = gql`
  mutation createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      errors
      comment {
        hidden
        htmlContent
        id
        markdownContent
        parentCommentId
        score
        creator {
          avatar {
            url
          }
          displayedName
        }
      }
    }
  }
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const ReadableDocument = gql`
  query readable(
    $readableId: ID!
    $readableType: String
    $language: LocaleEnum
    $viewToken: String
  ) {
    readable(
      readableId: $readableId
      readableType: $readableType
      language: $language
      viewToken: $viewToken
    ) {
      author {
        name
        company
        companyLogo {
          url(style: "list")
        }
        id
        kind
        position
        photo {
          url(style: "square")
        }
      }
      company {
        name
        id
        logo {
          url(style: "list")
        }
      }
      categories {
        key
        titles {
          pl
          en
        }
      }
      cover {
        url(style: "max_res")
      }
      content
      experiences
      hasAuthor
      id
      intro
      positions
      slug
      readableId
      readableType
      roles {
        key
        titles {
          pl
          en
        }
      }
      tags {
        key
        titles {
          pl
          en
        }
      }
      tier
      mainTag
      title
      language
      thumbsup
      thumbsupped
      wordCount
      publishedAt
      forIt
      forHr
      updated
    }
  }
`

/**
 * __useReadableQuery__
 *
 * To run a query within a React component, call `useReadableQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadableQuery({
 *   variables: {
 *      readableId: // value for 'readableId'
 *      readableType: // value for 'readableType'
 *      language: // value for 'language'
 *      viewToken: // value for 'viewToken'
 *   },
 * });
 */
export function useReadableQuery(
  baseOptions: Apollo.QueryHookOptions<ReadableQuery, ReadableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReadableQuery, ReadableQueryVariables>(
    ReadableDocument,
    options
  )
}
export function useReadableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadableQuery,
    ReadableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReadableQuery, ReadableQueryVariables>(
    ReadableDocument,
    options
  )
}
export type ReadableQueryHookResult = ReturnType<typeof useReadableQuery>
export type ReadableLazyQueryHookResult = ReturnType<
  typeof useReadableLazyQuery
>
export type ReadableQueryResult = Apollo.QueryResult<
  ReadableQuery,
  ReadableQueryVariables
>
export const RecommendationsReadablesDocument = gql`
  query recommendationsReadables(
    $language: LocaleEnum!
    $resourceId: ID!
    $resourceType: SearchableEnum!
    $returnType: SearchableEnum!
    $perPage: Int!
    $page: Int!
  ) {
    recommendations(
      language: $language
      resourceId: $resourceId
      resourceType: $resourceType
      returnType: $returnType
      perPage: $perPage
      page: $page
    ) {
      ...RecoReadable
    }
  }
  ${RecoReadableFragmentDoc}
`

/**
 * __useRecommendationsReadablesQuery__
 *
 * To run a query within a React component, call `useRecommendationsReadablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsReadablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsReadablesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      resourceId: // value for 'resourceId'
 *      resourceType: // value for 'resourceType'
 *      returnType: // value for 'returnType'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRecommendationsReadablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendationsReadablesQuery,
    RecommendationsReadablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RecommendationsReadablesQuery,
    RecommendationsReadablesQueryVariables
  >(RecommendationsReadablesDocument, options)
}
export function useRecommendationsReadablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendationsReadablesQuery,
    RecommendationsReadablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecommendationsReadablesQuery,
    RecommendationsReadablesQueryVariables
  >(RecommendationsReadablesDocument, options)
}
export type RecommendationsReadablesQueryHookResult = ReturnType<
  typeof useRecommendationsReadablesQuery
>
export type RecommendationsReadablesLazyQueryHookResult = ReturnType<
  typeof useRecommendationsReadablesLazyQuery
>
export type RecommendationsReadablesQueryResult = Apollo.QueryResult<
  RecommendationsReadablesQuery,
  RecommendationsReadablesQueryVariables
>
export const SearchReadablesDocument = gql`
  query searchReadables(
    $page: Int
    $perPage: Int
    $filters: ReadableFilters
    $language: LocaleEnum
  ) {
    searchReadables(
      page: $page
      perPage: $perPage
      filters: $filters
      language: $language
    ) {
      totalCount
      nodes {
        readableId
        slug
        readableType
        title
        company {
          name
          id
          logo {
            url
          }
        }
        author {
          photo {
            url(style: "small_square")
          }
          companyLogo {
            url
          }
          name
          position
        }
        cover {
          url(style: "large_thumb")
        }
        hasAuthor
        wordCount
        publishedAt
        updated
      }
    }
  }
`

/**
 * __useSearchReadablesQuery__
 *
 * To run a query within a React component, call `useSearchReadablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchReadablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchReadablesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSearchReadablesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchReadablesQuery,
    SearchReadablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchReadablesQuery, SearchReadablesQueryVariables>(
    SearchReadablesDocument,
    options
  )
}
export function useSearchReadablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchReadablesQuery,
    SearchReadablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchReadablesQuery,
    SearchReadablesQueryVariables
  >(SearchReadablesDocument, options)
}
export type SearchReadablesQueryHookResult = ReturnType<
  typeof useSearchReadablesQuery
>
export type SearchReadablesLazyQueryHookResult = ReturnType<
  typeof useSearchReadablesLazyQuery
>
export type SearchReadablesQueryResult = Apollo.QueryResult<
  SearchReadablesQuery,
  SearchReadablesQueryVariables
>
export const ToggleReactionReadableDocument = gql`
  mutation toggleReactionReadable($input: ToggleReactionReadableInput!) {
    toggleReactionReadable(input: $input) {
      success
    }
  }
`
export type ToggleReactionReadableMutationFn = Apollo.MutationFunction<
  ToggleReactionReadableMutation,
  ToggleReactionReadableMutationVariables
>

/**
 * __useToggleReactionReadableMutation__
 *
 * To run a mutation, you first call `useToggleReactionReadableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReactionReadableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReactionReadableMutation, { data, loading, error }] = useToggleReactionReadableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleReactionReadableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleReactionReadableMutation,
    ToggleReactionReadableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleReactionReadableMutation,
    ToggleReactionReadableMutationVariables
  >(ToggleReactionReadableDocument, options)
}
export type ToggleReactionReadableMutationHookResult = ReturnType<
  typeof useToggleReactionReadableMutation
>
export type ToggleReactionReadableMutationResult =
  Apollo.MutationResult<ToggleReactionReadableMutation>
export type ToggleReactionReadableMutationOptions = Apollo.BaseMutationOptions<
  ToggleReactionReadableMutation,
  ToggleReactionReadableMutationVariables
>
export const CreateCandidateDocument = gql`
  mutation createCandidate($input: CreateCandidateInput!) {
    createCandidate(input: $input) {
      success
      errors
    }
  }
`
export type CreateCandidateMutationFn = Apollo.MutationFunction<
  CreateCandidateMutation,
  CreateCandidateMutationVariables
>

/**
 * __useCreateCandidateMutation__
 *
 * To run a mutation, you first call `useCreateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateMutation, { data, loading, error }] = useCreateCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateMutation,
    CreateCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCandidateMutation,
    CreateCandidateMutationVariables
  >(CreateCandidateDocument, options)
}
export type CreateCandidateMutationHookResult = ReturnType<
  typeof useCreateCandidateMutation
>
export type CreateCandidateMutationResult =
  Apollo.MutationResult<CreateCandidateMutation>
export type CreateCandidateMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateMutation,
  CreateCandidateMutationVariables
>
export const ResetCandidatePasswordInstructionsDocument = gql`
  mutation resetCandidatePasswordInstructions(
    $input: ResetCandidatePasswordInstructionsInput!
  ) {
    resetCandidatePasswordInstructions(input: $input) {
      success
      errors
    }
  }
`
export type ResetCandidatePasswordInstructionsMutationFn =
  Apollo.MutationFunction<
    ResetCandidatePasswordInstructionsMutation,
    ResetCandidatePasswordInstructionsMutationVariables
  >

/**
 * __useResetCandidatePasswordInstructionsMutation__
 *
 * To run a mutation, you first call `useResetCandidatePasswordInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCandidatePasswordInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCandidatePasswordInstructionsMutation, { data, loading, error }] = useResetCandidatePasswordInstructionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetCandidatePasswordInstructionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCandidatePasswordInstructionsMutation,
    ResetCandidatePasswordInstructionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetCandidatePasswordInstructionsMutation,
    ResetCandidatePasswordInstructionsMutationVariables
  >(ResetCandidatePasswordInstructionsDocument, options)
}
export type ResetCandidatePasswordInstructionsMutationHookResult = ReturnType<
  typeof useResetCandidatePasswordInstructionsMutation
>
export type ResetCandidatePasswordInstructionsMutationResult =
  Apollo.MutationResult<ResetCandidatePasswordInstructionsMutation>
export type ResetCandidatePasswordInstructionsMutationOptions =
  Apollo.BaseMutationOptions<
    ResetCandidatePasswordInstructionsMutation,
    ResetCandidatePasswordInstructionsMutationVariables
  >
export const SigninCandidateDocument = gql`
  mutation signinCandidate($input: SignInCandidateInput!) {
    signinCandidate(input: $input) {
      token
      user {
        email
      }
      errors
    }
  }
`
export type SigninCandidateMutationFn = Apollo.MutationFunction<
  SigninCandidateMutation,
  SigninCandidateMutationVariables
>

/**
 * __useSigninCandidateMutation__
 *
 * To run a mutation, you first call `useSigninCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinCandidateMutation, { data, loading, error }] = useSigninCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninCandidateMutation,
    SigninCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SigninCandidateMutation,
    SigninCandidateMutationVariables
  >(SigninCandidateDocument, options)
}
export type SigninCandidateMutationHookResult = ReturnType<
  typeof useSigninCandidateMutation
>
export type SigninCandidateMutationResult =
  Apollo.MutationResult<SigninCandidateMutation>
export type SigninCandidateMutationOptions = Apollo.BaseMutationOptions<
  SigninCandidateMutation,
  SigninCandidateMutationVariables
>
export const SigninOauthDocument = gql`
  mutation signinOauth($input: SignInOauthInput!) {
    signinOauth(input: $input) {
      token
      user {
        email
        name
      }
    }
  }
`
export type SigninOauthMutationFn = Apollo.MutationFunction<
  SigninOauthMutation,
  SigninOauthMutationVariables
>

/**
 * __useSigninOauthMutation__
 *
 * To run a mutation, you first call `useSigninOauthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninOauthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinOauthMutation, { data, loading, error }] = useSigninOauthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninOauthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninOauthMutation,
    SigninOauthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SigninOauthMutation, SigninOauthMutationVariables>(
    SigninOauthDocument,
    options
  )
}
export type SigninOauthMutationHookResult = ReturnType<
  typeof useSigninOauthMutation
>
export type SigninOauthMutationResult =
  Apollo.MutationResult<SigninOauthMutation>
export type SigninOauthMutationOptions = Apollo.BaseMutationOptions<
  SigninOauthMutation,
  SigninOauthMutationVariables
>
export const UpdateMinSalaryDocument = gql`
  mutation updateMinSalary($input: UpdateMinSalaryInput!) {
    updateMinSalary(input: $input) {
      success
      errors
    }
  }
`
export type UpdateMinSalaryMutationFn = Apollo.MutationFunction<
  UpdateMinSalaryMutation,
  UpdateMinSalaryMutationVariables
>

/**
 * __useUpdateMinSalaryMutation__
 *
 * To run a mutation, you first call `useUpdateMinSalaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMinSalaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMinSalaryMutation, { data, loading, error }] = useUpdateMinSalaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMinSalaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMinSalaryMutation,
    UpdateMinSalaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMinSalaryMutation,
    UpdateMinSalaryMutationVariables
  >(UpdateMinSalaryDocument, options)
}
export type UpdateMinSalaryMutationHookResult = ReturnType<
  typeof useUpdateMinSalaryMutation
>
export type UpdateMinSalaryMutationResult =
  Apollo.MutationResult<UpdateMinSalaryMutation>
export type UpdateMinSalaryMutationOptions = Apollo.BaseMutationOptions<
  UpdateMinSalaryMutation,
  UpdateMinSalaryMutationVariables
>
export const ClaimCompanyDocument = gql`
  mutation claimCompany($input: ClaimCompanyInput!) {
    claimCompany(input: $input) {
      success
      errors
    }
  }
`
export type ClaimCompanyMutationFn = Apollo.MutationFunction<
  ClaimCompanyMutation,
  ClaimCompanyMutationVariables
>

/**
 * __useClaimCompanyMutation__
 *
 * To run a mutation, you first call `useClaimCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCompanyMutation, { data, loading, error }] = useClaimCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimCompanyMutation,
    ClaimCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClaimCompanyMutation,
    ClaimCompanyMutationVariables
  >(ClaimCompanyDocument, options)
}
export type ClaimCompanyMutationHookResult = ReturnType<
  typeof useClaimCompanyMutation
>
export type ClaimCompanyMutationResult =
  Apollo.MutationResult<ClaimCompanyMutation>
export type ClaimCompanyMutationOptions = Apollo.BaseMutationOptions<
  ClaimCompanyMutation,
  ClaimCompanyMutationVariables
>
export const SearchCompaniesListDocument = gql`
  query searchCompaniesList(
    $page: Int
    $perPage: Int
    $filters: CompanyFilters
    $order: CompanySearchOrderBy
    $language: LocaleEnum
  ) {
    searchCompanies(
      page: $page
      perPage: $perPage
      filters: $filters
      order: $order
      language: $language
    ) {
      totalCount
      nodes {
        id
        name
        activeOffersCount
        skills
        visible
        logo {
          url(style: "list")
        }
        locations {
          cityEn
          cityPl
        }
      }
    }
  }
`

/**
 * __useSearchCompaniesListQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSearchCompaniesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchCompaniesListQuery,
    SearchCompaniesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchCompaniesListQuery,
    SearchCompaniesListQueryVariables
  >(SearchCompaniesListDocument, options)
}
export function useSearchCompaniesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCompaniesListQuery,
    SearchCompaniesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchCompaniesListQuery,
    SearchCompaniesListQueryVariables
  >(SearchCompaniesListDocument, options)
}
export type SearchCompaniesListQueryHookResult = ReturnType<
  typeof useSearchCompaniesListQuery
>
export type SearchCompaniesListLazyQueryHookResult = ReturnType<
  typeof useSearchCompaniesListLazyQuery
>
export type SearchCompaniesListQueryResult = Apollo.QueryResult<
  SearchCompaniesListQuery,
  SearchCompaniesListQueryVariables
>
export const CompanyDocument = gql`
  query company($id: ID, $language: LocaleEnum, $viewToken: String) {
    company(id: $id, language: $language, viewToken: $viewToken) {
      activeOffersCount
      address
      sorting
      benefits {
        category
        localizedName
        name
        owner
      }
      coverPhoto {
        url(style: "large")
      }
      currentLayout
      employees
      galleryAssets {
        assetUrl
        description
        thumbnailUrl
        type
      }
      employeeQuotes {
        name
        quote
        photo {
          url(style: "thumb_square")
        }
        position
        linkedin
        twitter
        github
      }
      environment {
        paidVacations
        remotePossible
      }
      facebook
      github
      offices {
        address
        location {
          cityPl
          cityEn
        }
      }
      techUsage {
        lang {
          icon {
            url
          }
          name
        }
        platform {
          icon {
            url
          }
          name
        }
        tool {
          icon {
            url
          }
          name
        }
      }
      history
      howWeCode {
        bdd
        codeMetrics
        codeReview
        knowledgeDatabase
        pairProgramming
        staticCodeAnalysis
        styleGuide
        tdd
        versionControlDescription
      }
      howWeManage {
        architecturalDecisions
        canSwitchProjects
        methodology
        projectSoftware
        teamDesign
        teamDev
        teamPm
        teamQa
        techDecisions
      }
      howWeTest {
        automatedTesting
        ci
        integration
        manualTesting
        performance
        security
        system
        unit
      }
      id
      instagram
      jobCover {
        url
      }
      linkedin
      locale
      locations {
        cityPl
        cityEn
      }
      logo {
        url(style: "thumb")
      }
      name
      people {
        name
        insight
        photo {
          url
        }
        position
      }
      postalCode
      salaryBracketsHidden
      skills
      themeColor
      tools {
        additionalMonitor
        container
        freeSelection
        headphones
        laptop
        linux
        osx
        pc
        phone
        windows
      }
      twitter
      website
    }
  }
`

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *      viewToken: // value for 'viewToken'
 *   },
 * });
 */
export function useCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  )
}
export function useCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  )
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>
export type CompanyQueryResult = Apollo.QueryResult<
  CompanyQuery,
  CompanyQueryVariables
>
export const MeDocument = gql`
  query me {
    me {
      ... on Candidate {
        avatar {
          url
        }
        email
        likedJobs
        rejectedJobs
        employmentType
        expectedSalary
        linkedin
        cvFileName
        location
        minSalary
        name
        otherTech
        phone
        relocation
        remote
        role
        seniority
        tech
        tech1
        tech1Experience
        tech2
        tech2Experience
      }
      ... on User {
        avatar {
          url
        }
        email
        name
      }
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const MetadataDocument = gql`
  query metadata(
    $companyFilters: CompanyFilters
    $id: ID
    $jobFilters: JobFilters
    $language: LocaleEnum!
    $pageType: PageEnum!
    $readableFilters: ReadableFilters
    $url: String
  ) {
    metadata(
      companyFilters: $companyFilters
      id: $id
      jobFilters: $jobFilters
      language: $language
      pageType: $pageType
      readableFilters: $readableFilters
      url: $url
    ) {
      canonicalUrl
      description
      hreflangEnUrl
      hreflangPlUrl
      imageUrl
      noindex
      title
      jsonLd
      breadcrumbJsonLd
      header
      subheader
    }
  }
`

/**
 * __useMetadataQuery__
 *
 * To run a query within a React component, call `useMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataQuery({
 *   variables: {
 *      companyFilters: // value for 'companyFilters'
 *      id: // value for 'id'
 *      jobFilters: // value for 'jobFilters'
 *      language: // value for 'language'
 *      pageType: // value for 'pageType'
 *      readableFilters: // value for 'readableFilters'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<MetadataQuery, MetadataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetadataQuery, MetadataQueryVariables>(
    MetadataDocument,
    options
  )
}
export function useMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetadataQuery,
    MetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetadataQuery, MetadataQueryVariables>(
    MetadataDocument,
    options
  )
}
export type MetadataQueryHookResult = ReturnType<typeof useMetadataQuery>
export type MetadataLazyQueryHookResult = ReturnType<
  typeof useMetadataLazyQuery
>
export type MetadataQueryResult = Apollo.QueryResult<
  MetadataQuery,
  MetadataQueryVariables
>
export const ApplyCvDocument = gql`
  mutation applyCv($input: ApplyCvInput!) {
    applyCv(input: $input) {
      clientMutationId
      errors
      poposal {
        id
      }
    }
  }
`
export type ApplyCvMutationFn = Apollo.MutationFunction<
  ApplyCvMutation,
  ApplyCvMutationVariables
>

/**
 * __useApplyCvMutation__
 *
 * To run a mutation, you first call `useApplyCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCvMutation, { data, loading, error }] = useApplyCvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyCvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyCvMutation,
    ApplyCvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ApplyCvMutation, ApplyCvMutationVariables>(
    ApplyCvDocument,
    options
  )
}
export type ApplyCvMutationHookResult = ReturnType<typeof useApplyCvMutation>
export type ApplyCvMutationResult = Apollo.MutationResult<ApplyCvMutation>
export type ApplyCvMutationOptions = Apollo.BaseMutationOptions<
  ApplyCvMutation,
  ApplyCvMutationVariables
>
export const ApplyExternalDocument = gql`
  mutation applyExternal($input: ApplyExternalInput!) {
    applyExternal(input: $input) {
      applyUrl
    }
  }
`
export type ApplyExternalMutationFn = Apollo.MutationFunction<
  ApplyExternalMutation,
  ApplyExternalMutationVariables
>

/**
 * __useApplyExternalMutation__
 *
 * To run a mutation, you first call `useApplyExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyExternalMutation, { data, loading, error }] = useApplyExternalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyExternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyExternalMutation,
    ApplyExternalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApplyExternalMutation,
    ApplyExternalMutationVariables
  >(ApplyExternalDocument, options)
}
export type ApplyExternalMutationHookResult = ReturnType<
  typeof useApplyExternalMutation
>
export type ApplyExternalMutationResult =
  Apollo.MutationResult<ApplyExternalMutation>
export type ApplyExternalMutationOptions = Apollo.BaseMutationOptions<
  ApplyExternalMutation,
  ApplyExternalMutationVariables
>
export const ApplyWithProfileDocument = gql`
  mutation applyWithProfile($input: ApplyWithProfileInput!) {
    applyWithProfile(input: $input) {
      clientMutationId
      errors
      proposal {
        id
      }
    }
  }
`
export type ApplyWithProfileMutationFn = Apollo.MutationFunction<
  ApplyWithProfileMutation,
  ApplyWithProfileMutationVariables
>

/**
 * __useApplyWithProfileMutation__
 *
 * To run a mutation, you first call `useApplyWithProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyWithProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyWithProfileMutation, { data, loading, error }] = useApplyWithProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyWithProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyWithProfileMutation,
    ApplyWithProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApplyWithProfileMutation,
    ApplyWithProfileMutationVariables
  >(ApplyWithProfileDocument, options)
}
export type ApplyWithProfileMutationHookResult = ReturnType<
  typeof useApplyWithProfileMutation
>
export type ApplyWithProfileMutationResult =
  Apollo.MutationResult<ApplyWithProfileMutation>
export type ApplyWithProfileMutationOptions = Apollo.BaseMutationOptions<
  ApplyWithProfileMutation,
  ApplyWithProfileMutationVariables
>
export const HideOfferDocument = gql`
  mutation hideOffer($input: HideInput!) {
    hideOffer(input: $input) {
      success
    }
  }
`
export type HideOfferMutationFn = Apollo.MutationFunction<
  HideOfferMutation,
  HideOfferMutationVariables
>

/**
 * __useHideOfferMutation__
 *
 * To run a mutation, you first call `useHideOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideOfferMutation, { data, loading, error }] = useHideOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHideOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HideOfferMutation,
    HideOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<HideOfferMutation, HideOfferMutationVariables>(
    HideOfferDocument,
    options
  )
}
export type HideOfferMutationHookResult = ReturnType<
  typeof useHideOfferMutation
>
export type HideOfferMutationResult = Apollo.MutationResult<HideOfferMutation>
export type HideOfferMutationOptions = Apollo.BaseMutationOptions<
  HideOfferMutation,
  HideOfferMutationVariables
>
export const JobDocument = gql`
  query job($id: ID, $viewToken: String) {
    job(id: $id, viewToken: $viewToken) {
      applyViaProfile
      contractB2b
      contractEmployment
      contractOther
      details
      employmentType
      endsAt
      experienceLevel
      minExperienceInYears
      hiddenBrackets
      highlight
      mainTechnology
      matchingUserBrackets
      offer
      position
      publishedAt
      recruitmentProcess
      remote
      requirements
      roles
      showSalary
      state
      technologyTags
      redirectTo
      workFrom
      workModes
      topBanner {
        url
      }
      benefits {
        category
        localizedName
        name
        owner
      }
      consents {
        id
        required
        text
      }
      company {
        id
        history
        activeOffersCount
        address
        managed
        employees
        themeColor
        published
        environment {
          paidVacations
          remotePossible
        }
        benefits {
          category
          localizedName
          name
          owner
        }
        coverPhoto {
          url
        }
        jobCover {
          url
        }
        currentLayout
        logo {
          url(style: "thumb")
        }
        name
      }
      environment {
        paidVacations
        remotePossible
      }
      locale
      locations {
        address
        location {
          cityPl
          cityEn
        }
      }
      howWeCode {
        bdd
        codeMetrics
        codeReview
        knowledgeDatabase
        pairProgramming
        staticCodeAnalysis
        styleGuide
        tdd
        versionControlDescription
      }
      howWeManage {
        architecturalDecisions
        canSwitchProjects
        methodology
        projectSoftware
        teamDesign
        teamDev
        teamPm
        teamQa
        techDecisions
      }
      howWeTest {
        automatedTesting
        ci
        integration
        manualTesting
        performance
        security
        system
        unit
      }
      id
      internalApplication
      employmentSalary {
        currency
        hidden
        maxValue
        minValue
        money
        timeframe
      }
      b2bSalary {
        currency
        hidden
        maxValue
        minValue
        money
        timeframe
      }
      otherSalary {
        currency
        hidden
        maxValue
        minValue
        money
        timeframe
      }
      tools {
        additionalMonitor
        container
        freeSelection
        headphones
        laptop
        linux
        osx
        pc
        phone
        windows
      }
    }
  }
`

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *      viewToken: // value for 'viewToken'
 *   },
 * });
 */
export function useJobQuery(
  baseOptions?: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options)
}
export function useJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options)
}
export type JobQueryHookResult = ReturnType<typeof useJobQuery>
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>
export const JobSalaryMatchDocument = gql`
  query jobSalaryMatch($minSalary: Int!, $jobId: ID!) {
    jobSalaryMatch(minSalary: $minSalary, jobId: $jobId) {
      matchingUserBrackets
    }
  }
`

/**
 * __useJobSalaryMatchQuery__
 *
 * To run a query within a React component, call `useJobSalaryMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSalaryMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSalaryMatchQuery({
 *   variables: {
 *      minSalary: // value for 'minSalary'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobSalaryMatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSalaryMatchQuery,
    JobSalaryMatchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobSalaryMatchQuery, JobSalaryMatchQueryVariables>(
    JobSalaryMatchDocument,
    options
  )
}
export function useJobSalaryMatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSalaryMatchQuery,
    JobSalaryMatchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobSalaryMatchQuery, JobSalaryMatchQueryVariables>(
    JobSalaryMatchDocument,
    options
  )
}
export type JobSalaryMatchQueryHookResult = ReturnType<
  typeof useJobSalaryMatchQuery
>
export type JobSalaryMatchLazyQueryHookResult = ReturnType<
  typeof useJobSalaryMatchLazyQuery
>
export type JobSalaryMatchQueryResult = Apollo.QueryResult<
  JobSalaryMatchQuery,
  JobSalaryMatchQueryVariables
>
export const SearchJobsDocument = gql`
  query searchJobs(
    $page: Int
    $perPage: Int
    $filters: JobFilters
    $order: JobsSearchOrderBy
    $language: LocaleEnum
    $boostWhere: BoostWhere
    $exclude: [ID!]
  ) {
    searchJobs(
      page: $page
      perPage: $perPage
      filters: $filters
      order: $order
      language: $language
      boostWhere: $boostWhere
      exclude: $exclude
    ) {
      totalCount
      nodes {
        id
        company {
          name
          logo {
            url(style: "list")
          }
        }
        denominatedSalaryLong {
          money
          currency
          hidden
        }
        environment {
          remotePossible
        }
        redirectTo
        highlight
        city
        experienceLevel
        employmentType
        hiddenBrackets
        matchingUserBrackets
        position
        remote
        showSalary
        technologyTags
        contractB2b
        contractEmployment
        contractOther
        locale
      }
    }
  }
`

/**
 * __useSearchJobsQuery__
 *
 * To run a query within a React component, call `useSearchJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *      language: // value for 'language'
 *      boostWhere: // value for 'boostWhere'
 *      exclude: // value for 'exclude'
 *   },
 * });
 */
export function useSearchJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchJobsQuery,
    SearchJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchJobsQuery, SearchJobsQueryVariables>(
    SearchJobsDocument,
    options
  )
}
export function useSearchJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchJobsQuery,
    SearchJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchJobsQuery, SearchJobsQueryVariables>(
    SearchJobsDocument,
    options
  )
}
export type SearchJobsQueryHookResult = ReturnType<typeof useSearchJobsQuery>
export type SearchJobsLazyQueryHookResult = ReturnType<
  typeof useSearchJobsLazyQuery
>
export type SearchJobsQueryResult = Apollo.QueryResult<
  SearchJobsQuery,
  SearchJobsQueryVariables
>
export const JobsLocationsDocument = gql`
  query jobsLocations($amount: Int, $language: LocaleEnum!) {
    jobsLocations(amount: $amount, language: $language)
  }
`

/**
 * __useJobsLocationsQuery__
 *
 * To run a query within a React component, call `useJobsLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsLocationsQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useJobsLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobsLocationsQuery,
    JobsLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobsLocationsQuery, JobsLocationsQueryVariables>(
    JobsLocationsDocument,
    options
  )
}
export function useJobsLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobsLocationsQuery,
    JobsLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobsLocationsQuery, JobsLocationsQueryVariables>(
    JobsLocationsDocument,
    options
  )
}
export type JobsLocationsQueryHookResult = ReturnType<
  typeof useJobsLocationsQuery
>
export type JobsLocationsLazyQueryHookResult = ReturnType<
  typeof useJobsLocationsLazyQuery
>
export type JobsLocationsQueryResult = Apollo.QueryResult<
  JobsLocationsQuery,
  JobsLocationsQueryVariables
>
export const LikeOfferDocument = gql`
  mutation likeOffer($input: LikeOfferInput!) {
    likeOffer(input: $input) {
      success
    }
  }
`
export type LikeOfferMutationFn = Apollo.MutationFunction<
  LikeOfferMutation,
  LikeOfferMutationVariables
>

/**
 * __useLikeOfferMutation__
 *
 * To run a mutation, you first call `useLikeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeOfferMutation, { data, loading, error }] = useLikeOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LikeOfferMutation,
    LikeOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LikeOfferMutation, LikeOfferMutationVariables>(
    LikeOfferDocument,
    options
  )
}
export type LikeOfferMutationHookResult = ReturnType<
  typeof useLikeOfferMutation
>
export type LikeOfferMutationResult = Apollo.MutationResult<LikeOfferMutation>
export type LikeOfferMutationOptions = Apollo.BaseMutationOptions<
  LikeOfferMutation,
  LikeOfferMutationVariables
>
export const ShowOfferDocument = gql`
  mutation showOffer($input: ShowInput!) {
    showOffer(input: $input) {
      success
    }
  }
`
export type ShowOfferMutationFn = Apollo.MutationFunction<
  ShowOfferMutation,
  ShowOfferMutationVariables
>

/**
 * __useShowOfferMutation__
 *
 * To run a mutation, you first call `useShowOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showOfferMutation, { data, loading, error }] = useShowOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShowOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShowOfferMutation,
    ShowOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ShowOfferMutation, ShowOfferMutationVariables>(
    ShowOfferDocument,
    options
  )
}
export type ShowOfferMutationHookResult = ReturnType<
  typeof useShowOfferMutation
>
export type ShowOfferMutationResult = Apollo.MutationResult<ShowOfferMutation>
export type ShowOfferMutationOptions = Apollo.BaseMutationOptions<
  ShowOfferMutation,
  ShowOfferMutationVariables
>
export const DislikeOfferDocument = gql`
  mutation dislikeOffer($input: DislikeInput!) {
    dislikeOffer(input: $input) {
      success
    }
  }
`
export type DislikeOfferMutationFn = Apollo.MutationFunction<
  DislikeOfferMutation,
  DislikeOfferMutationVariables
>

/**
 * __useDislikeOfferMutation__
 *
 * To run a mutation, you first call `useDislikeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikeOfferMutation, { data, loading, error }] = useDislikeOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDislikeOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DislikeOfferMutation,
    DislikeOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DislikeOfferMutation,
    DislikeOfferMutationVariables
  >(DislikeOfferDocument, options)
}
export type DislikeOfferMutationHookResult = ReturnType<
  typeof useDislikeOfferMutation
>
export type DislikeOfferMutationResult =
  Apollo.MutationResult<DislikeOfferMutation>
export type DislikeOfferMutationOptions = Apollo.BaseMutationOptions<
  DislikeOfferMutation,
  DislikeOfferMutationVariables
>
export const RecommendationsDocument = gql`
  query recommendations(
    $language: LocaleEnum!
    $resourceId: ID!
    $resourceType: SearchableEnum!
    $returnType: SearchableEnum!
    $perPage: Int!
    $page: Int!
    $boostWhere: BoostWhere
    $minResults: Int
  ) {
    recommendations(
      language: $language
      resourceId: $resourceId
      resourceType: $resourceType
      returnType: $returnType
      perPage: $perPage
      page: $page
      boostWhere: $boostWhere
      minResults: $minResults
    ) {
      ...Reco
    }
  }
  ${RecoFragmentDoc}
`

/**
 * __useRecommendationsQuery__
 *
 * To run a query within a React component, call `useRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      resourceId: // value for 'resourceId'
 *      resourceType: // value for 'resourceType'
 *      returnType: // value for 'returnType'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      boostWhere: // value for 'boostWhere'
 *      minResults: // value for 'minResults'
 *   },
 * });
 */
export function useRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecommendationsQuery, RecommendationsQueryVariables>(
    RecommendationsDocument,
    options
  )
}
export function useRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >(RecommendationsDocument, options)
}
export type RecommendationsQueryHookResult = ReturnType<
  typeof useRecommendationsQuery
>
export type RecommendationsLazyQueryHookResult = ReturnType<
  typeof useRecommendationsLazyQuery
>
export type RecommendationsQueryResult = Apollo.QueryResult<
  RecommendationsQuery,
  RecommendationsQueryVariables
>
export const RegisterB2cLeadDocument = gql`
  mutation registerB2cLead($input: RegisterB2cLeadInput!) {
    registerB2cLead(input: $input) {
      success
    }
  }
`
export type RegisterB2cLeadMutationFn = Apollo.MutationFunction<
  RegisterB2cLeadMutation,
  RegisterB2cLeadMutationVariables
>

/**
 * __useRegisterB2cLeadMutation__
 *
 * To run a mutation, you first call `useRegisterB2cLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterB2cLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerB2cLeadMutation, { data, loading, error }] = useRegisterB2cLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterB2cLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterB2cLeadMutation,
    RegisterB2cLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterB2cLeadMutation,
    RegisterB2cLeadMutationVariables
  >(RegisterB2cLeadDocument, options)
}
export type RegisterB2cLeadMutationHookResult = ReturnType<
  typeof useRegisterB2cLeadMutation
>
export type RegisterB2cLeadMutationResult =
  Apollo.MutationResult<RegisterB2cLeadMutation>
export type RegisterB2cLeadMutationOptions = Apollo.BaseMutationOptions<
  RegisterB2cLeadMutation,
  RegisterB2cLeadMutationVariables
>
export const RegisterEventDocument = gql`
  mutation registerEvent($input: RegisterInput!) {
    registerEvent(input: $input) {
      success
    }
  }
`
export type RegisterEventMutationFn = Apollo.MutationFunction<
  RegisterEventMutation,
  RegisterEventMutationVariables
>

/**
 * __useRegisterEventMutation__
 *
 * To run a mutation, you first call `useRegisterEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEventMutation, { data, loading, error }] = useRegisterEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterEventMutation,
    RegisterEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterEventMutation,
    RegisterEventMutationVariables
  >(RegisterEventDocument, options)
}
export type RegisterEventMutationHookResult = ReturnType<
  typeof useRegisterEventMutation
>
export type RegisterEventMutationResult =
  Apollo.MutationResult<RegisterEventMutation>
export type RegisterEventMutationOptions = Apollo.BaseMutationOptions<
  RegisterEventMutation,
  RegisterEventMutationVariables
>
export const RegisterLeadDocument = gql`
  mutation registerLead($input: RegisterLeadInput!) {
    registerLead(input: $input) {
      success
    }
  }
`
export type RegisterLeadMutationFn = Apollo.MutationFunction<
  RegisterLeadMutation,
  RegisterLeadMutationVariables
>

/**
 * __useRegisterLeadMutation__
 *
 * To run a mutation, you first call `useRegisterLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLeadMutation, { data, loading, error }] = useRegisterLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterLeadMutation,
    RegisterLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterLeadMutation,
    RegisterLeadMutationVariables
  >(RegisterLeadDocument, options)
}
export type RegisterLeadMutationHookResult = ReturnType<
  typeof useRegisterLeadMutation
>
export type RegisterLeadMutationResult =
  Apollo.MutationResult<RegisterLeadMutation>
export type RegisterLeadMutationOptions = Apollo.BaseMutationOptions<
  RegisterLeadMutation,
  RegisterLeadMutationVariables
>
export const ResetCandidatePasswordByTokenDocument = gql`
  mutation resetCandidatePasswordByToken(
    $input: ResetCandidatePasswordByTokenInput!
  ) {
    resetCandidatePasswordByToken(input: $input) {
      success
      errors
    }
  }
`
export type ResetCandidatePasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetCandidatePasswordByTokenMutation,
  ResetCandidatePasswordByTokenMutationVariables
>

/**
 * __useResetCandidatePasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetCandidatePasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCandidatePasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCandidatePasswordByTokenMutation, { data, loading, error }] = useResetCandidatePasswordByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetCandidatePasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCandidatePasswordByTokenMutation,
    ResetCandidatePasswordByTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetCandidatePasswordByTokenMutation,
    ResetCandidatePasswordByTokenMutationVariables
  >(ResetCandidatePasswordByTokenDocument, options)
}
export type ResetCandidatePasswordByTokenMutationHookResult = ReturnType<
  typeof useResetCandidatePasswordByTokenMutation
>
export type ResetCandidatePasswordByTokenMutationResult =
  Apollo.MutationResult<ResetCandidatePasswordByTokenMutation>
export type ResetCandidatePasswordByTokenMutationOptions =
  Apollo.BaseMutationOptions<
    ResetCandidatePasswordByTokenMutation,
    ResetCandidatePasswordByTokenMutationVariables
  >
export const SalaryStatsDocument = gql`
  query salaryStats($id: ID, $language: LocaleEnum) {
    salaryStats(id: $id, language: $language) {
      id
      title
      intro
      description
      position
      lastUpdate
      reportedTitle
      salaryBracketsTitle
      reported {
        all {
          ...SalaryReportFields
        }
        junior {
          ...SalaryReportFields
        }
        medium {
          ...SalaryReportFields
        }
        senior {
          ...SalaryReportFields
        }
      }
      salaryBrackets {
        all {
          ...SalaryReportFields
        }
        junior {
          ...SalaryReportFields
        }
        medium {
          ...SalaryReportFields
        }
        senior {
          ...SalaryReportFields
        }
      }
    }
  }
  ${SalaryReportFieldsFragmentDoc}
`

/**
 * __useSalaryStatsQuery__
 *
 * To run a query within a React component, call `useSalaryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalaryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalaryStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSalaryStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SalaryStatsQuery,
    SalaryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SalaryStatsQuery, SalaryStatsQueryVariables>(
    SalaryStatsDocument,
    options
  )
}
export function useSalaryStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalaryStatsQuery,
    SalaryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SalaryStatsQuery, SalaryStatsQueryVariables>(
    SalaryStatsDocument,
    options
  )
}
export type SalaryStatsQueryHookResult = ReturnType<typeof useSalaryStatsQuery>
export type SalaryStatsLazyQueryHookResult = ReturnType<
  typeof useSalaryStatsLazyQuery
>
export type SalaryStatsQueryResult = Apollo.QueryResult<
  SalaryStatsQuery,
  SalaryStatsQueryVariables
>
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      errors
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const ResetUserPasswordByTokenDocument = gql`
  mutation resetUserPasswordByToken($input: ResetUserPasswordByTokenInput!) {
    resetUserPasswordByToken(input: $input) {
      success
      errors
    }
  }
`
export type ResetUserPasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetUserPasswordByTokenMutation,
  ResetUserPasswordByTokenMutationVariables
>

/**
 * __useResetUserPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordByTokenMutation, { data, loading, error }] = useResetUserPasswordByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordByTokenMutation,
    ResetUserPasswordByTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetUserPasswordByTokenMutation,
    ResetUserPasswordByTokenMutationVariables
  >(ResetUserPasswordByTokenDocument, options)
}
export type ResetUserPasswordByTokenMutationHookResult = ReturnType<
  typeof useResetUserPasswordByTokenMutation
>
export type ResetUserPasswordByTokenMutationResult =
  Apollo.MutationResult<ResetUserPasswordByTokenMutation>
export type ResetUserPasswordByTokenMutationOptions =
  Apollo.BaseMutationOptions<
    ResetUserPasswordByTokenMutation,
    ResetUserPasswordByTokenMutationVariables
  >
export const ResetUserPasswordInstructionsDocument = gql`
  mutation resetUserPasswordInstructions(
    $input: ResetUserPasswordInstructionsInput!
  ) {
    resetUserPasswordInstructions(input: $input) {
      success
      errors
    }
  }
`
export type ResetUserPasswordInstructionsMutationFn = Apollo.MutationFunction<
  ResetUserPasswordInstructionsMutation,
  ResetUserPasswordInstructionsMutationVariables
>

/**
 * __useResetUserPasswordInstructionsMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordInstructionsMutation, { data, loading, error }] = useResetUserPasswordInstructionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordInstructionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordInstructionsMutation,
    ResetUserPasswordInstructionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetUserPasswordInstructionsMutation,
    ResetUserPasswordInstructionsMutationVariables
  >(ResetUserPasswordInstructionsDocument, options)
}
export type ResetUserPasswordInstructionsMutationHookResult = ReturnType<
  typeof useResetUserPasswordInstructionsMutation
>
export type ResetUserPasswordInstructionsMutationResult =
  Apollo.MutationResult<ResetUserPasswordInstructionsMutation>
export type ResetUserPasswordInstructionsMutationOptions =
  Apollo.BaseMutationOptions<
    ResetUserPasswordInstructionsMutation,
    ResetUserPasswordInstructionsMutationVariables
  >
export const SigninUserDocument = gql`
  mutation signinUser($input: SignInUserInput!) {
    signinUser(input: $input) {
      token
      user {
        email
      }
      errors
    }
  }
`
export type SigninUserMutationFn = Apollo.MutationFunction<
  SigninUserMutation,
  SigninUserMutationVariables
>

/**
 * __useSigninUserMutation__
 *
 * To run a mutation, you first call `useSigninUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinUserMutation, { data, loading, error }] = useSigninUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninUserMutation,
    SigninUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SigninUserMutation, SigninUserMutationVariables>(
    SigninUserDocument,
    options
  )
}
export type SigninUserMutationHookResult = ReturnType<
  typeof useSigninUserMutation
>
export type SigninUserMutationResult = Apollo.MutationResult<SigninUserMutation>
export type SigninUserMutationOptions = Apollo.BaseMutationOptions<
  SigninUserMutation,
  SigninUserMutationVariables
>
